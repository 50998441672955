import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack, CheckCircle } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Button, Card, CircularProgress, Container, Grid, Typography } from "@mui/material";
import DeleteSpecimenModal from "./DeleteSpecimenModal";
import moment from "moment-timezone";
import SpecimenPhotoModal from "../AddSpecimen/components/modals/SpecimenPhotoModal";
import SpecimenImagesCarousel from "./SpecimenImagesCarousel";
import { useGetSpecimenHistory } from "hooks/specimenTracking/useGetSpecimenHistory/useGetSpecimenHistory";
import { useGetSpecimenLocations } from "hooks/specimenTracking/useGetSpecimenLocations/useGetSpecimenLocations";
import useTranslation from "hooks/useTranslation";
import { useUserType } from "hooks/useUserType/useUserType";
import { useIsMobile } from "util/deviceUtils";
import { getFullNameFromParts } from "util/util_functions";
import { TimelineDataSpecimenHistory } from "../../types";
import { useSpecimenStyles } from "../../styles";

const SpecimenTrackingHistory = () => {
  const { classes } = useSpecimenStyles();
  const navigate = useNavigate();
  const locations = useGetSpecimenLocations();
  const isMobile = useIsMobile();
  const { specimenId } = useParams<{ specimenId: string }>();
  const { getSpecimenHistory, isLoading } = useGetSpecimenHistory();
  const [timelineData, setTimelineData] = useState<TimelineDataSpecimenHistory[]>([]);
  const [showSpecimenPhotoModal, setShowSpecimenPhotoModal] = useState<boolean>(false);
  const [showDeleteSpecimenModal, setShowDeleteSpecimenModal] = useState<boolean>(false);
  const { isAdmin, isManager, isSpecimenTech } = useUserType();

  const {
    web: { specimenTracking },
  } = useTranslation();

  const getLocationName = (collectedAt) => {
    const location = locations.specimenLocationList?.find(
      (location) => location.id === collectedAt,
    );
    if (location) {
      return location.name;
    }
  };

  const getFormattedCollectedDate = (collectedDatetime) => {
    const momentObj = moment(collectedDatetime);
    const userTimezone = moment.tz.guess();
    const currentDate = moment().tz(userTimezone);

    if (momentObj.isSame(currentDate, "day")) {
      return "Today";
    }

    const formattedDateTime = momentObj.tz(userTimezone).format("MM/DD/YYYY");
    return formattedDateTime;
  };

  const getFormattedCollectedTime = (collectedDatetime) => {
    const parsedDateTime = moment(collectedDatetime);
    const userTimezone = moment.tz.guess();
    const localDateTime = parsedDateTime.tz(userTimezone);
    const formattedTime = localDateTime.format("h:mm A");

    return formattedTime;
  };

  const getSpecimenProcessType = (type: string) => {
    switch (type) {
      case "DROP_OFF":
        return specimenTracking.droppedOffBy;
      case "PICK_UP":
        return specimenTracking.pickedUpby;
      default:
        return specimenTracking.addedBy;
    }
  };

  const updateSpecimenImage = (updatedImage, specimenExternalId) => {
    setTimelineData((prevCodes) => {
      return prevCodes.map((code) => {
        if (code.specimenSpecimenExternalId === specimenExternalId) {
          return { ...code, specimenSpecimenImage: updatedImage };
        }
        return code;
      });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSpecimenHistory(specimenId);
      if (response?.data) {
        const { data } = response;
        const sortedByDateData = data.sort((a, b) =>
          b.collectedDatetime.localeCompare(a.collectedDatetime),
        );
        setTimelineData(sortedByDateData);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    specimenOrderPatientFirstName,
    specimenOrderPatientLastName,
    specimenOrderMrn,
    specimenSpecimenExternalId,
    specimenImage,
    specimenSpecimenImage,
  } = timelineData.length > 0 ? timelineData[0] : ({} as TimelineDataSpecimenHistory);

  return (
    <>
      <Grid container className={isMobile ? classes.mobileContent : classes.content}>
        <Box className={classes.titleContainer} alignItems="center">
          <Button
            className={classes.backButton}
            variant="text"
            onClick={() => navigate("/console/specimen-tracking")}
            role="button"
          >
            <ArrowBack />
          </Button>

          <Typography className={classes.scanLocationTitle} color="inherit" variant="body1">
            {specimenTracking.historyTitle}
          </Typography>
        </Box>
        <Container className={classes.historyWrapper}>
          <Grid
            container
            justifyContent={isMobile ? "center" : "flex-start"}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} sm={8}>
              <Typography className={classes.specimenTextInfo}>
                <strong>
                  {specimenTracking.specimenId}: {specimenSpecimenExternalId}
                </strong>
              </Typography>
              <Typography className={classes.specimenTextInfo}>
                <strong>
                  {" "}
                  {specimenTracking.mrnId}: {specimenOrderMrn}
                </strong>
              </Typography>
              <Typography className={classes.specimenTextInfo} style={{ marginBottom: "16px" }}>
                <strong>
                  {specimenTracking.patientName}:{" "}
                  {getFullNameFromParts(
                    specimenOrderPatientFirstName,
                    specimenOrderPatientLastName,
                  )}
                </strong>
              </Typography>
            </Grid>
            {!isMobile && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ gap: "16px" }}
              >
                {specimenImage && (
                  <img
                    src={specimenImage}
                    alt="Please reload"
                    style={{ maxWidth: "280px", width: "100%" }}
                  />
                )}
                {specimenSpecimenImage && (
                  <img
                    src={specimenSpecimenImage}
                    alt="Please reload"
                    style={{ maxWidth: "550px", width: "100%" }}
                  />
                )}
              </Box>
            )}
            {isMobile && (
              <SpecimenImagesCarousel
                specimenLabelImage={specimenImage}
                specimenSecondImage={specimenSpecimenImage}
                setShowModal={setShowSpecimenPhotoModal}
              />
            )}
          </Grid>
          {isLoading ? (
            <CircularProgress data-testid="specimenHistory-loader" />
          ) : (
            <Timeline data-testid="trackingHistory-timeline">
              <TimelineConnector className={classes.timelineConector} />
              {timelineData.map(
                (
                  {
                    collectedDatetime,
                    collectedByLastName,
                    collectedByFirstName,
                    collectedAt,
                    type,
                    inFinalDestination,
                  },
                  index,
                ) => {
                  const isLastItem = index === timelineData.length - 1;
                  return (
                    <TimelineItem
                      key={`timeline-${index.toString()}`}
                      className={classes.timelineItem}
                    >
                      <TimelineSeparator>
                        <TimelineDot className={classes.timelineDot} />
                        {!isLastItem && (
                          <TimelineConnector style={{ backgroundColor: "#F18F28", width: "5px" }} />
                        )}
                      </TimelineSeparator>
                      <TimelineContent className={classes.timelineContent}>
                        <Box className={classes.timelineHeaderCard}>
                          <Typography style={{ fontWeight: "500" }}>
                            {getFormattedCollectedDate(collectedDatetime)}
                          </Typography>
                        </Box>
                        <Card className={classes.timelineCardContent}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography className={classes.infoTitle}>
                              {getSpecimenProcessType(type)}:{" "}
                              {getFullNameFromParts(collectedByFirstName, collectedByLastName)}
                            </Typography>
                            {inFinalDestination && (
                              <CheckCircle color="primary" className={classes.checkIcon} />
                            )}
                          </Box>
                          <Typography className={classes.infoText}>
                            {specimenTracking.trackedAt}: {getLocationName(collectedAt)}
                          </Typography>
                          <Typography className={classes.infoText}>
                            {specimenTracking.trackedOn}:{" "}
                            {getFormattedCollectedTime(collectedDatetime)}
                          </Typography>
                        </Card>
                      </TimelineContent>
                    </TimelineItem>
                  );
                },
              )}
            </Timeline>
          )}
        </Container>
      </Grid>
      <SpecimenPhotoModal
        open={showSpecimenPhotoModal}
        onClose={() => setShowSpecimenPhotoModal(false)}
        replacePhoto
        updateSpecimenImage={updateSpecimenImage}
        specimenDetail={{
          id: specimenId,
          specimenExternalId: specimenSpecimenExternalId,
          specimenImage: specimenSpecimenImage,
        }}
      />
      <DeleteSpecimenModal
        open={showDeleteSpecimenModal}
        onClose={() => setShowDeleteSpecimenModal(false)}
        specimenDetail={{
          id: specimenId,
          specimenExternalId: specimenSpecimenExternalId,
        }}
      />
      {isMobile && (isAdmin || isManager || isSpecimenTech) && (
        <Button
          color="error"
          variant="outlined"
          onClick={() => setShowDeleteSpecimenModal(true)}
          style={{ marginBottom: "2em" }}
        >
          DELETE SPECIMEN
        </Button>
      )}
    </>
  );
};

export default SpecimenTrackingHistory;
