import React, { useState } from "react";
import { FormControl, Grid, TextField } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import useTranslation from "hooks/useTranslation";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const NotesSection: React.FC<TabPaneProps> = ({ shouldDisableForm }: TabPaneProps) => {
  const {
    web: {
      dataCuration: {
        providerProfile: {
          notesAndAdditionalInfo: {
            notes: { internalNotes },
          },
        },
      },
    },
  } = useTranslation();

  const {
    providerNotes: { notes },
    setCurrentPayload,
  } = useProviderContext();

  const [formData, setFormData] = useState(notes);

  const handleChange = (key: string, value: any) => {
    const newValue = value || "";
    setFormData({
      ...formData,
      [key]: newValue,
    });
    if (newValue !== notes[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const parseHTMLToText = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent ?? "";
  };

  const parsedInternalNotes =
    typeof formData.internalNotes === "string"
      ? parseHTMLToText(formData.internalNotes || notes.internalNotes)
      : "";

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      padding="16px 0 32px 0"
      data-testid="notes-section"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="internalNotes"
            name="internalNotes"
            size="medium"
            minRows={8}
            maxRows={8}
            value={parsedInternalNotes || ""}
            multiline
            variant="outlined"
            label={internalNotes}
            onChange={(e: any) => handleChange("internalNotes", e.target.value)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="NotesSection-InternalNotesStatementInput"
            data-testid="NotesSection-InternalNotesStatementInput"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NotesSection;
