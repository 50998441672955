import React, { useState } from "react";
import { Add, Chat, Delete } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStyles } from "./utils/styles";
import { TableToolbarProps } from "./utils/types";

const TableToolbar: React.FC<TableToolbarProps> = (props: TableToolbarProps) => {
  const { classes } = useStyles();
  const {
    setSearchParams,
    numSelected,
    colDefs,
    showLocationsFilter = false,
    showAddNewButton = true,
    showExportCsvButton = false,
    exportToCSVChangeLog,
  } = props;

  const searchableColumns = colDefs.filter((col) => col.searchable);

  const [searchBy, setSearchBy] = useState(searchableColumns[0]?.searchByValue || "");
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    setSearchParams({ searchBy, searchText });
  };

  const clearSearch = () => {
    setSearchText("");
    setSearchParams({ searchBy: "", searchText: "" });
  };

  return (
    <Toolbar className={classes.rootTableToolbar}>
      <Box
        width="100%"
        position="relative"
        display="flex"
        alignItems="flex-end"
        style={{ gap: "32px" }}
      >
        {showLocationsFilter && (
          <Autocomplete
            id="combo-box-demo"
            options={[{ title: "Location 1" }, { title: "Location 2" }, { title: "Location 3" }]}
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Locations" variant="outlined" />}
            data-testid="locations-filter"
          />
        )}

        <FormGroup className={classes.searchBar_wrapper} data-testid="search-bar-section">
          {searchableColumns.length > 0 && (
            <FormControl style={{ width: "156px" }}>
              <Typography textAlign="left" fontSize="14px" color="gray" marginBottom="4px">
                Search by
              </Typography>
              <Select
                displayEmpty
                placeholder="Search by"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                size="small"
                classes={{ root: classes.searchBy_select }}
                inputProps={{ "data-testid": "search-by-select" }}
              >
                {searchableColumns.map((col) => (
                  <MenuItem key={col.id} value={col.searchByValue}>
                    {col.label === "Name" ? "Last Name" : col.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Box className={classes.textField_wrapper}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              classes={{ root: classes.textField_searchText }}
            />
            <Button
              onClick={handleSearch}
              variant="contained"
              color="primary"
              disabled={!searchText}
            >
              Search
            </Button>
            {searchText && (
              <IconButton className={classes.clearIcon} disabled={!searchText}>
                <CancelIcon onClick={clearSearch} />
              </IconButton>
            )}
          </Box>
        </FormGroup>
      </Box>

      {numSelected > 0 ? (
        <Box className={classes.actionButtons_wrapper} data-testid="action-buttons">
          <Typography data-testid="select-message">{numSelected} items selected</Typography>
          <Box>
            <Tooltip title="Send Message">
              <IconButton aria-label="send-message">
                <Chat />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" style={{ marginLeft: "16px" }}>
              <IconButton aria-label="delete">
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <>
          {showAddNewButton && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<Add />}
              style={{ marginLeft: "auto", height: "36px", margin: "20px 0 0 auto" }}
            >
              Add New
            </Button>
          )}
          {showExportCsvButton && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "auto", height: "36px", margin: "20px 0 0 auto" }}
              onClick={() => exportToCSVChangeLog()}
            >
              Export CSV
            </Button>
          )}
        </>
      )}
    </Toolbar>
  );
};

export default TableToolbar;
