import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, TextField } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useLocationContext } from "../../LocationContext";
import markerIcon from "views/NewAppointment/assets/marker.png";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "views/DataCuration/ProviderProfile/types";

const AddressSection: React.FC<TabPaneProps> = ({ shouldDisableForm }: TabPaneProps) => {
  const {
    web: {
      dataCuration: {
        location: {
          generalInfo: {
            addressSection: {
              buildingNameLabel,
              address1Label,
              address2Label,
              cityLabel,
              stateLabel,
              zipLabel,
              googleMapUrlLabel,
            },
          },
        },
      },
    },
  } = useTranslation();

  const { locationData, currentPayload, setCurrentPayload } = useLocationContext();

  const {
    generalInfo: {
      address,
      name: { nameFull },
      contactInfo: { phone },
    },
  } = locationData;

  const {
    buildingName,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zip,
    googleMapUrl,
    latitude,
    longitude,
  } = address;

  const [formData, setFormData] = useState({
    buildingName,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zip,
    googleMapUrl,
    latitude,
    longitude,
  });

  const isAddressField = (key: string) => ["streetAddress1", "city", "state", "zip"].includes(key);

  const updatePayload = (updatedField: Record<string, any>, fieldKey: string) => {
    setCurrentPayload((prevPayload) => {
      return {
        ...prevPayload,
        generalInfo: {
          ...(prevPayload?.generalInfo || {}),
          address: {
            ...(prevPayload?.generalInfo?.address || {}),
            [fieldKey]: updatedField[fieldKey] || formData[fieldKey],
          },

          ...(isAddressField(fieldKey)
            ? {
                contactInfo: { phone },
                name: { nameFull },
              }
            : {}),
        },
      };
    });
  };

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    updatePayload({ [key]: newValue }, key);
  };

  const updateFormData = () => {
    setFormData({
      buildingName,
      streetAddress1,
      streetAddress2,
      city,
      state,
      zip,
      googleMapUrl,
      latitude,
      longitude,
    });
  };

  useEffect(() => {
    updateFormData();
  }, [locationData]);

  useEffect(() => {
    if (currentPayload === null) {
      updateFormData();
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      padding="16px"
      data-testid="AddressSection"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="buildingName"
            name="buildingName"
            size="medium"
            value={formData.buildingName ?? buildingName}
            variant="outlined"
            label={buildingNameLabel}
            onChange={(e) => handleChange("buildingName", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="AddressSection-buildingName"
            data-testid="AddressSection-buildingName"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="address1"
            name="address1"
            size="medium"
            value={formData.streetAddress1 ?? streetAddress1}
            variant="outlined"
            label={address1Label}
            onChange={(e) => handleChange("streetAddress1", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="AddressSection-Address1Input"
            data-testid="AddressSection-Address1Input"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="address2"
            name="address2"
            size="medium"
            value={formData.streetAddress2 ?? streetAddress2}
            variant="outlined"
            label={address2Label}
            onChange={(e) => handleChange("streetAddress2", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="AddressSection-Address2Input"
            data-testid="AddressSection-Address2Input"
          />
        </FormControl>
      </Grid>
      <Box width="100%" />
      {/* Section 2 start */}
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="city"
            name="city"
            size="medium"
            value={formData.city ?? city}
            variant="outlined"
            label={cityLabel}
            onChange={(e) => handleChange("city", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="AddressSection-CityInput"
            data-testid="AddressSection-CityInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="state"
            name="state"
            size="medium"
            value={formData.state ?? state}
            variant="outlined"
            label={stateLabel}
            onChange={(e) => handleChange("state", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="AddressSection-StateInput"
            data-testid="AddressSection-StateInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="zip"
            name="zip"
            size="medium"
            value={formData.zip ?? zip}
            variant="outlined"
            label={zipLabel}
            onChange={(e) => handleChange("zip", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="AddressSection-ZipInput"
            data-testid="AddressSection-ZipInput"
          />
        </FormControl>
      </Grid>
      {process.env.REACT_APP_GOOGLE_MAP_API_KEY && (
        <Grid item xs={12} md={12}>
          <FormControl variant="outlined" fullWidth style={{ marginBottom: 10 }}>
            <TextField
              error={false}
              id="googleMapUrl"
              name="googleMapUrl"
              size="medium"
              value={formData.googleMapUrl ?? googleMapUrl}
              variant="outlined"
              label={googleMapUrlLabel}
              onChange={(e) => handleChange("googleMapUrl", e)}
              helperText={false}
              disabled
              data-cy="AddressSection-GoogleMapUrlInput"
              data-testid="AddressSection-GoogleMapUrlInput"
            />
          </FormControl>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "250px" }}
              center={{ lat: Number(latitude), lng: Number(longitude) }}
              zoom={9}
            >
              <Marker
                key={latitude}
                position={{ lat: Number(latitude), lng: Number(longitude) }}
                icon={markerIcon}
              />
            </GoogleMap>
          </LoadScript>
          <Box width="100%" display="flex" marginTop="16px" style={{ gap: "16px" }}>
            <TextField
              error={false}
              id="latitude"
              name="latitude"
              size="medium"
              value={formData.latitude ?? latitude}
              variant="outlined"
              label="Latitude"
              onChange={(e) => handleChange("latitude", e)}
              helperText={false}
              disabled
              data-cy="AddressSection-Latitude"
              data-testid="AddressSection-Latitude"
            />
            <TextField
              error={false}
              id="longitude"
              name="longitude"
              size="medium"
              value={formData.longitude ?? longitude}
              variant="outlined"
              label="Longitude"
              onChange={(e) => handleChange("longitude", e)}
              helperText={false}
              disabled
              data-cy="AddressSection-Longitude"
              data-testid="AddressSection-Longitude"
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default AddressSection;
