import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";
import { SpecimenTransformedDataForMobile } from "views/SpecimenTracking/types";

export const useGetSpecimenLast = (
  searchBy?: string,
  searchValue?: string,
  collectedBy?: string | number,
) => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const clearSearchParams = () => {
    searchBy = undefined;
    searchValue = undefined;
  };

  const fetchQuery = async () => {
    let url = `${API_ENDPOINTS.specimenTrackingGetLast}`;

    let collectedByTrimmed = collectedBy?.toString().trim().toUpperCase();
    if (collectedByTrimmed === "ALL" || collectedByTrimmed === "COLLECTED_BY") {
      collectedByTrimmed = undefined;
    }

    if (searchBy && searchValue) {
      url += `?search_by=${searchBy}&search_text=${searchValue}`;
    }

    if (collectedByTrimmed !== null && collectedByTrimmed !== undefined) {
      url += `${searchBy && searchValue ? "&" : "?"}collected_by=${collectedByTrimmed}`;
    }

    const response = await axiosClient.get(url);
    return response.data;
  };

  const transformedDataForMobile = (responseData) => {
    if (isEmpty(responseData)) {
      return [];
    }

    const groupedData = responseData.reduce((partialResult, current) => {
      const result = partialResult;
      if (!result[current.specimenOrderMrn]) {
        result[current.specimenOrderMrn] = [];
      }
      result[current.specimenOrderMrn].push(current);
      return result;
    }, {});

    const transformedData: SpecimenTransformedDataForMobile[] = Object.keys(groupedData).map(
      (specimenOrderMrn) => ({
        collectedDatetime: groupedData[specimenOrderMrn][0].collectedDatetime,
        specimen: groupedData[specimenOrderMrn][0],
        specimenOrderId: groupedData[specimenOrderMrn][0].specimenOrderId,
        specimenOrderExternalId: groupedData[specimenOrderMrn][0].specimenOrderOrderExternalId,
        specimenOrderMrn,
        specimenData: groupedData[specimenOrderMrn].map((specimen) => specimen),
      }),
    );

    return transformedData;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.specimenTrackingHistory],
    queryFn: fetchQuery,
    staleTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  return {
    data,
    refetch,
    isLoading,
    isFetching,
    transformedDataForMobile,
    clearSearchParams,
  };
};
