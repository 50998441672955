import { ProviderGeneralInfoAndLocation } from "../types";

type CancelProps = {
  setSections: React.Dispatch<React.SetStateAction<any>>;
  setIsEditing: React.Dispatch<React.SetStateAction<any>>;
  setCancel: React.Dispatch<React.SetStateAction<any>>;
  setCurrentPayload: React.Dispatch<React.SetStateAction<ProviderGeneralInfoAndLocation | null>>;
  setCurrentlyEditing: React.Dispatch<React.SetStateAction<string | null>>;
};

const handleCancel = ({
  setSections,
  setIsEditing,
  setCancel,
  setCurrentPayload,
  setCurrentlyEditing,
}: CancelProps) => {
  setIsEditing({
    personalInformation: false,
    practice: false,
    additionalInformation: false,
    insurancesAccepted: false,
    schInformation: false,
  });
  setSections({
    personalInformation: true,
    practice: true,
    additionalInformation: true,
    insurancesAccepted: true,
    schInformation: true,
  });
  setCancel((c) => !c);
  setCurrentPayload(null);
  setCurrentlyEditing(null);
};

export default handleCancel;
