import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useStylesAddModal } from "./styles";

const PatientMismatchErrorModal = ({
  showMismatchModal,
  setShowMismatchModal,
  handleScanNewSpecimen,
  setShowDetailsModal,
  setSpecimenData,
}) => {
  const app = useApp();
  const { classes } = useStylesAddModal();
  const {
    web: {
      specimenTrackingAdd: { specimenId: specimenIdLabel, orderId: orderIdLabel },
    },
  } = useTranslation();

  const { patient } = showMismatchModal;

  const [orderId, setOrderId] = useState(patient?.orderOrderExternalId || "");
  const [specimenId, setSpecimenId] = useState(patient?.specimenExternalId || "");
  const [patientMRN, setPatientMRN] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [loadingPatient, setLoadingPatient] = useState(false);

  const handleSearchPatient = async () => {
    try {
      setLoadingPatient(true);
      const resp = await axiosClient.get(
        `${API_ENDPOINTS.specimentTrackingGetOrder}?search_by=orderExternalId&search_text=${orderId}`,
      );
      if (resp.data) {
        if (resp.data.data.length > 0) {
          setPatientLastName(resp.data.data[0].patientLastName);
          setPatientMRN(resp.data.data[0].mrn);
        } else {
          app.addError("No patient found with this order id.");
          setPatientLastName("");
          setPatientMRN("");
        }
      }
    } catch (error) {
      app.addError("No patient found with this order id.");
    } finally {
      setLoadingPatient(false);
    }
  };

  const debouncedSearch = debounce(handleSearchPatient, 500);

  useEffect(() => {
    if (orderId) {
      debouncedSearch();
    }

    return () => debouncedSearch.cancel();
  }, [orderId]);

  const handleAddSpecimen = async () => {
    try {
      setLoadingPatient(true);
      const resp = await handleScanNewSpecimen(orderId, specimenId);
      if (resp?.data) {
        setSpecimenData(resp.data.data);
        setShowMismatchModal(false);
        setShowDetailsModal(true);
      }
    } finally {
      setLoadingPatient(false);
    }
  };

  useEffect(() => {
    if (patient) {
      setOrderId(patient.orderOrderExternalId || "");
      setSpecimenId(patient.specimenExternalId || "");
    }
  }, [patient]);

  return (
    <Modal open={showMismatchModal.show} data-testid="add-manually-modal">
      <Box className={classes.modalContainer} style={{ padding: "1em" }}>
        <Typography fontSize="18px" fontWeight={600}>
          Error identifying patient.
        </Typography>
        <Typography fontSize="16px"> Please update and confirm the below.</Typography>
        <FormGroup style={{ margin: "16px 0" }}>
          <TextField
            required
            label={orderIdLabel}
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            style={{ marginBottom: "16px" }}
            inputProps={{ "data-testid": "orderIdInput" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ display: "flex", alignItems: "center" }}>
                  <span>Ord</span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            label={specimenIdLabel}
            value={specimenId}
            onChange={(e) => setSpecimenId(e.target.value)}
            inputProps={{ "data-testid": "specimenIdInput" }}
          />
        </FormGroup>
        {loadingPatient ? (
          <CircularProgress
            style={{ margin: "16px auto 24px auto", display: "block" }}
            data-testid="loading"
          />
        ) : (
          <Box margin="16px 0 24px 0">
            <Box display="flex" gap="4px">
              <Typography className={classes.labelText} style={{ marginRight: 0 }}>
                MRN:
              </Typography>
              <Typography className={classes.valueText} data-testid="patientMRN">
                {patientMRN}
              </Typography>
            </Box>
            <Box display="flex" gap="4px">
              <Typography className={classes.labelText} style={{ marginRight: 0 }}>
                Patient Last Name:
              </Typography>
              <Typography className={classes.valueText}> {patientLastName}</Typography>
            </Box>
          </Box>
        )}
        <Button
          variant="contained"
          fullWidth
          disabled={!patientMRN || loadingPatient}
          onClick={handleAddSpecimen}
          style={{ marginBottom: "16px" }}
        >
          Add Specimen
        </Button>
        <Button variant="contained" fullWidth onClick={() => setShowMismatchModal(false)}>
          Rescan
        </Button>
      </Box>
    </Modal>
  );
};

export default PatientMismatchErrorModal;
