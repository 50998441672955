import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { ProviderGeneralInfoAndLocation } from "../types";

type EditProps = {
  slug: string;
  providerId: string | undefined;
  setSections: React.Dispatch<React.SetStateAction<any>>;
  setIsEditing: React.Dispatch<React.SetStateAction<any>>;
  setIsLoading: React.Dispatch<React.SetStateAction<any>>;
  currentPayload: ProviderGeneralInfoAndLocation | null;
  setCurrentPayload: React.Dispatch<React.SetStateAction<ProviderGeneralInfoAndLocation | null>>;
  app: any;
  setCurrentlyEditing: React.Dispatch<React.SetStateAction<string | null>>;
  isEditing: boolean;
};

const handleEdit = async ({
  slug,
  providerId,
  setSections,
  setIsEditing,
  setIsLoading,
  currentPayload,
  setCurrentPayload,
  app,
  setCurrentlyEditing,
  isEditing,
}: EditProps) => {
  setIsEditing((prev) => ({
    ...prev,
    [slug]: !prev[slug],
  }));
  setSections((prev) => ({
    ...prev,
    [slug]: !prev[slug],
  }));

  if (currentPayload) {
    setIsLoading((prev) => ({
      ...prev,
      [slug]: true,
    }));
    try {
      if (isEditing) {
        await axiosClient.patch(`${API_ENDPOINTS.dataCurationProviders}${providerId}`, {
          ...currentPayload,
        });
        app.addInfoMsg("Provider updated successfully");
        setCurrentlyEditing(null);
      }
    } catch (error) {
      app.addError("Error updating provider. Please try again.");
    } finally {
      setCurrentPayload(null);
      setIsLoading((prev) => ({
        ...prev,
        [slug]: false,
      }));
    }
  } else {
    setCurrentlyEditing((prev) => (prev === slug ? null : slug));
  }
};

export default handleEdit;
