import { useQuery } from "react-query";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { PracticeLocation } from "views/DataCuration/ProviderProfile/types";

async function searchLocations(searchText: string) {
  const response = await axiosClient.get(API_ENDPOINTS.dataCurationLocations, {
    params: {
      searchBy: "name",
      searchText,
      page_size: 50,
    },
  });
  if (response?.data?.status === "error") {
    throw new Error("Error searching locations");
  }
  return response?.data?.data as PracticeLocation[];
}

const useSearchLocations = (searchText: string) =>
  useQuery(["dataCuration", "searchLocations", searchText], () => searchLocations(searchText));

export { useSearchLocations };
