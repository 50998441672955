import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableCells from "./TableCells";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";
import { getComparator, stableSort } from "./utils";
import { useStyles } from "./utils/styles";
import { Order, TableProps } from "./utils/types";
import { axiosClient } from "util/api_helper";
import { exportCSV } from "util/util_functions";

const DataCurationTable: React.FC<TableProps> = (props: TableProps) => {
  const {
    rows,
    colDefs,
    dataUrl,
    linkTo,
    showLocationsFilter,
    id,
    showAddNewButton,
    allowSelection,
    defaultOrderBy = "",
    showExportCsvButton,
    cellWidth,
  } = props;

  const { classes } = useStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rowsData, setRowsData] = useState<any>([]);
  const [dataCount, setDataCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({ searchBy: "", searchText: "" });

  useEffect(() => {
    setPage(0);
  }, [searchParams]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let finalUrl = dataUrl;
      let filtersUsed = false;

      if (searchParams.searchBy && searchParams.searchText) {
        filtersUsed = true;
        const searchQuery = `search_by=${encodeURIComponent(
          searchParams.searchBy,
        )}&search_text=${encodeURIComponent(searchParams.searchText)}`;
        finalUrl = `${dataUrl}?${searchQuery}`;
      }

      finalUrl = `${finalUrl}${
        filtersUsed ? "&" : "?"
      }page_no=${page}&page_size=${rowsPerPage}&order=${order}&order_by=${orderBy}`;

      try {
        const { data } = await axiosClient.get(finalUrl);
        setRowsData(data?.data);
        setDataCount(data?.totalRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
        setRowsData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dataUrl, rows, searchParams, page, rowsPerPage, order, orderBy]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rowsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, (rowsData?.length || 0) - page * rowsPerPage);

  return (
    <div data-testid={id}>
      <Paper className={classes.paper} elevation={0}>
        <TableToolbar
          numSelected={selected.length}
          colDefs={colDefs}
          showLocationsFilter={showLocationsFilter}
          showAddNewButton={showAddNewButton}
          showExportCsvButton={showExportCsvButton}
          setSearchParams={setSearchParams}
          exportToCSVChangeLog={() => exportCSV(rowsData, "change-log-data-report")}
        />
        <TableContainer style={{ height: "50vh", marginTop: "16px" }}>
          {loading ? (
            <CircularProgress style={{ position: "relative", top: "10em" }} />
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              data-testid="data-curation-table"
              stickyHeader
            >
              <TableHeader
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowsData?.length}
                rows={rowsData}
                colDefs={colDefs}
                allowSelection={allowSelection}
              />
              <TableBody className={classes.tableBody}>
                {stableSort(rowsData, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableCells
                      key={row.id}
                      row={row}
                      colDefs={colDefs}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                      linkTo={linkTo}
                      allowSelection={allowSelection}
                      cellWidth={cellWidth}
                    />
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default DataCurationTable;
