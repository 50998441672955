import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import { useGetLocationTypes } from "hooks/dataCuration/useGetLocationTypes";
import {
  ParentLocation,
  useSearchParentLocations,
} from "hooks/dataCuration/useSearchParentLocations";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "views/DataCuration/ProviderProfile/types";
import useStyles from "views/DOTForm/styles/forms.styles";

const NameSection: React.FC<TabPaneProps> = ({ shouldDisableForm }: TabPaneProps) => {
  const { classes } = useStyles({ alignLeft: true });
  const { locationTypesList } = useGetLocationTypes();

  const {
    web: {
      dataCuration: {
        location: {
          generalInfo: {
            nameSection: { locationIdTitle, parentLocationLabel, locationTypeTitle },
          },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: {
      generalInfo: {
        name,
        address: { streetAddress1, state, city, zip },
        contactInfo: { phone, webUrl },
      },
    },
    setCurrentPayload,
  } = useLocationContext();

  const { data: parentLocations = [], isLoading, isError, error } = useSearchParentLocations();

  const locationList = [
    {
      label: "Location Name",
      key: "name",
      helperText: "Display name on location cards and search results.",
    },
    {
      label: "Location Page Title",
      key: "nameFull",
      helperText: "This should be unique for each location, and includes geographic information.",
    },
    {
      label: "Location Page Name (URL)",
      key: "webUrl",
      helperText:
        "This is a short description of what the location is (example: breast center, cancer center).",
    },
  ];

  const [formData, setFormData] = useState(
    locationList.reduce((acc, { key }) => {
      if (key === "webUrl") {
        acc[key] = webUrl || "";
      } else {
        acc[key] = name[key] || "";
      }
      return acc;
    }, {} as Record<string, string>),
  );
  const [locationType, setLocationType] = useState(name.type ?? "");
  const [parentLocation, setParentLocation] = useState<ParentLocation | null>(null);

  const handleChange = (key: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e?.target?.value;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== name[key]) {
      if (key === "webUrl") {
        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          generalInfo: {
            ...(prevPayload?.generalInfo || {}),
            contactInfo: {
              ...(prevPayload?.generalInfo?.contactInfo || {}),
              webUrl: newValue,
            },
          },
        }));
      } else {
        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          generalInfo: {
            ...(prevPayload?.generalInfo || {}),
            name: {
              ...(prevPayload?.generalInfo?.name || {}),
              [key]: newValue,
            },
            address: {
              streetAddress1,
              state,
              city,
              zip,
            },
            contactInfo: {
              phone,
              webUrl,
            },
          },
        }));
      }
    }
  };

  const handleLocationTypeChange = (e: SelectChangeEvent<string>) => {
    const newType = e.target.value;
    setLocationType(newType);

    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        name: {
          ...(prevPayload?.generalInfo?.name || {}),
          type: newType,
        },
      },
    }));
  };

  const handleParentLocationChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: ParentLocation | null,
  ) => {
    setParentLocation(newValue || null);

    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        name: {
          ...(prevPayload?.generalInfo?.name || {}),
          parent_location: newValue ? { id: newValue.id } : null,
        },
      },
    }));
  };

  useEffect(() => {
    if (name.parent_location) {
      const selectedParentLocation = parentLocations.find(
        (location) => location.id === (name.parent_location?.id || ""),
      );
      setParentLocation(selectedParentLocation || null);
    }
  }, [name.parent_location, parentLocations]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      className={classes.personalInformation_wrapper}
      data-testid="NameSection"
    >
      <Grid item xs={12} md={12} style={{ margin: "16px 0" }}>
        <FormControl variant="outlined" fullWidth style={{ textAlign: "start" }}>
          <h6 style={{ fontFamily: "Alegreya sans", fontSize: "16px", fontWeight: "400" }}>
            {locationIdTitle}
          </h6>
          <span style={{ color: "gray" }}>{name.ahsId}</span>
        </FormControl>
      </Grid>
      {locationList.map(({ label, key, helperText }, index) => (
        <Grid item xs={12} md={12} key={key + index.toString()}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              error={false}
              id={key}
              name={key}
              size="medium"
              value={formData[key]}
              variant="outlined"
              label={label}
              onChange={(e) => handleChange(key, e)}
              disabled={shouldDisableForm}
              helperText={helperText}
              data-cy={`NameSection-${key}Input`}
              data-testid={`NameSection-${key}Input`}
            />
          </FormControl>
        </Grid>
      ))}

      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth style={{ marginBottom: "24px" }} error={isError}>
          <Autocomplete
            id="parentLocation"
            options={parentLocations}
            getOptionLabel={(option) => option?.nameFull || ""}
            renderInput={(params) => <TextField {...params} label={error || parentLocationLabel} />}
            onChange={handleParentLocationChange}
            loading={isLoading}
            disabled={shouldDisableForm}
            value={parentLocation || null}
            clearOnEscape
            data-cy="NameSection-parentLocationInput"
            data-testid="NameSection-parentLocationInput"
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="LocationType-label" disabled={shouldDisableForm}>
            {locationTypeTitle}
          </InputLabel>
          <Select
            labelId="locationType-label"
            id="locationType"
            value={locationType}
            label={locationTypeTitle}
            disabled={shouldDisableForm}
            style={{ textAlign: "start" }}
            onChange={handleLocationTypeChange}
          >
            {locationTypesList?.map((type, index) => (
              <MenuItem value={type} key={type + index.toString()}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NameSection;
