import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Props } from "./types";
import { useStyles } from "./style";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "header",
  "size",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const RichTextArea = ({
  initialValue = "",
  onChange,
  placeHolder = "",
  readOnly = false,
}: Props) => {
  const { classes } = useStyles({ readOnly });
  const [value, setValue] = useState(initialValue);

  const handleChange = (content: string) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <ReactQuill
      className={classes.app}
      value={value}
      onChange={handleChange}
      modules={modules}
      formats={formats}
      placeholder={placeHolder}
      readOnly={readOnly}
    />
  );
};

export default RichTextArea;
