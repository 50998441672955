import React from "react";
import DataCurationTable from "./DataCurationTable";
import GenericTabs, { TabConfig } from "./Tabs";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import {
  colDefsChangeLog,
  colDefsLocations,
  colDefsProviders,
} from "./DataCurationTable/utils/colDefs";
import { rowsChangeLog } from "./DataCurationTable/utils/mocks";

const DataCurationPage: React.FC = () => {
  const providerUrl = "data-curation/provider";
  const locationUrl = `data-curation/location`;

  const tabsConfig: TabConfig = {
    label: ["Providers", "Locations", "Change Log"],
    component: [
      <DataCurationTable
        key="providersTable"
        dataUrl={API_ENDPOINTS.dataCurationProviders}
        colDefs={colDefsProviders}
        linkTo={providerUrl}
        id="providersTable"
        allowSelection
        defaultOrderBy="lastName"
        cellWidth="20%"
      />,
      <DataCurationTable
        key="locationsTable"
        dataUrl={API_ENDPOINTS.dataCurationLocations}
        colDefs={colDefsLocations}
        linkTo={locationUrl}
        id="locationsTable"
        allowSelection
        defaultOrderBy="id"
        cellWidth="14.2%"
      />,
      <DataCurationTable
        key="changeLogTable"
        rows={rowsChangeLog}
        colDefs={colDefsChangeLog}
        showLocationsFilter={false}
        showAddNewButton={false}
        showExportCsvButton
        id="changeLogTable"
        allowSelection={false}
        cellWidth="16.6%"
      />,
    ],
  };

  return <GenericTabs config={tabsConfig} />;
};

export default DataCurationPage;
