import React, { useState } from "react";
import { Autocomplete, Box, Chip, Grid, TextField, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "../../types";

const ConditionsAndSymptomsSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    web: {
      dataCuration: { providerProfile },
    },
  } = useTranslation();

  const {
    clinical: { conditionsAndSymptoms },
  } = providerProfile;

  const [autocompleteValue, setAutocompleteValue] = useState<string | null>("");
  const [conditions, setConditions] = useState<string[]>([]);

  const handleChangeConditions = (event: React.SyntheticEvent, value: string | null) => {
    if (value && !conditions.some((condition) => condition === value)) {
      setConditions([...conditions, value]);
    }
    setAutocompleteValue(value);
  };

  const handleRemoveCondition = (selectdCondition: string) => {
    setConditions(conditions.filter((condition) => condition !== selectdCondition));
    setAutocompleteValue(null);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      paddingY="16px"
      data-testid="conditions-and-symptoms-section"
    >
      <Grid item xs={12} md={12} style={{ marginTop: "16px" }}>
        <div style={{ textAlign: "start" }}>
          <h6 style={{ color: "gray" }}>{conditionsAndSymptoms.subtitle}</h6>
          <h6 style={{ color: "gray" }}>{conditionsAndSymptoms.subtitle2}</h6>
        </div>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={autocompleteValue}
          options={conditionsAndSymptoms.options.length ? conditionsAndSymptoms.options : []}
          renderInput={(params) => <TextField {...params} label="Search Conditions & Symptoms" />}
          // @ts-ignore-next-line
          onChange={handleChangeConditions}
          style={{ margin: "24px 0 16px 0", maxWidth: "400px" }}
          disabled={shouldDisableForm}
        />
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="8px">
          {conditions.length === 0 ? (
            <Typography color="gray">No conditions selected</Typography>
          ) : (
            conditions.map((condition) => (
              <Chip
                key={condition}
                label={condition}
                onDelete={() => handleRemoveCondition(condition)}
                disabled={shouldDisableForm}
              />
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConditionsAndSymptomsSection;
