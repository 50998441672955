import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";

type BackButtonProps = {
  url: string;
};

const BackButton: React.FC<BackButtonProps> = ({ url }: BackButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ArrowBackIosNewIcon />}
      variant="outlined"
      onClick={() => navigate(url)}
      tabIndex={0}
      type="button"
      style={{
        margin: "16px",
        marginRight: "auto",
        display: "flex",
      }}
    >
      Go to List
    </Button>
  );
};

export default BackButton;
