import React, { useMemo, useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { AddManuallyModal } from "./addManuallyModal";
import useTranslation from "hooks/useTranslation";
import { SpecimenAddResponse } from "views/SpecimenTracking/types";

export const AddManuallyAddNewSpecimenModal = ({ handleScanNewSpecimen }) => {
  const specimenIdRef = useRef<HTMLInputElement>(null);
  const [specimenExternalId, setSpecimenExternalId] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [specimenInfo, setSpecimenInfo] = useState<SpecimenAddResponse>();
  const [error, setError] = useState<string>("");
  const [specimenExternalIdError, setSpecimenExternalIdError] = useState<string>("");

  const {
    web: {
      specimenTrackingAdd: { specimenId: specimenIdLabel, orderId: orderIdLabel },
    },
  } = useTranslation();

  const cleanSpecimenId = () => {
    setSpecimenExternalId("");
    setError("");
  };

  const isSaveDisabled = useMemo(
    () => isDisabled || !orderId || !specimenExternalId || !!specimenExternalIdError,
    [isDisabled, orderId, specimenExternalId, specimenExternalIdError],
  );

  const searchSpecimen = async () => {
    setIsDisabled(true);
    if (specimenExternalId && orderId) {
      const resp = await handleScanNewSpecimen(orderId, `HSC-${specimenExternalId}`);
      if (resp?.data) {
        setSpecimenInfo(resp.data);
        cleanSpecimenId();
        setIsDisabled(false);
      } else {
        cleanSpecimenId();
        setSpecimenInfo(undefined);
        setIsDisabled(false);
        setError(resp?.msg);
      }

      if (specimenIdRef?.current) {
        specimenIdRef.current.focus();
      }
    }
  };

  const handleSpecimenExternalIdChange = (val) => {
    const specimenId = val.target.value;
    setSpecimenExternalId(specimenId);
    setError("");
    setSpecimenExternalIdError(
      specimenId.startsWith("0")
        ? `Specimen ID "HSC-${specimenId}" is invalid, cannot start with a 0 number`
        : "",
    );
  };

  return (
    <AddManuallyModal
      searchSpecimen={searchSpecimen}
      specimenInfo={specimenInfo}
      error={error}
      disableSave={isSaveDisabled}
      isLoading={isDisabled}
      onCloseModal={() => {
        setOrderId("");
        setSpecimenExternalId("");
      }}
    >
      <Box display="flex" flexDirection="column">
        <TextField
          type="number"
          data-testid="text-specimen-add-manually"
          data-cy="orderIdInput"
          style={{ flex: 1, marginBottom: "1rem" }}
          autoFocus
          value={orderId}
          required
          id="outlined-basic"
          label={orderIdLabel}
          variant="outlined"
          onChange={(val) => {
            setOrderId(val.target.value);
            setError("");
          }}
          inputProps={{
            "data-testid": "orderIdInput",
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ display: "flex", alignItems: "center" }}>
                <span>Ord</span>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type="number"
          data-testid="text-specimen-add-manually"
          data-cy="specimenIdInput"
          style={{ flex: 1, marginBottom: "1rem" }}
          inputRef={specimenIdRef}
          value={specimenExternalId}
          required
          id="outlined-basic"
          label={specimenIdLabel}
          variant="outlined"
          error={!!specimenExternalIdError}
          helperText={specimenExternalIdError}
          onChange={handleSpecimenExternalIdChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              searchSpecimen();
              event.preventDefault();
            }
          }}
          inputProps={{
            "data-testid": "specimenIdInput",
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ display: "flex", alignItems: "center" }}>
                <span>HSC-</span>
              </InputAdornment>
            ),
          }}
          disabled={isDisabled}
        />
      </Box>
    </AddManuallyModal>
  );
};
