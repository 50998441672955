import React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  tabsNav: {
    margin: "18px 18px 0 18px",
    alignSelf: "flex-start",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`data-governanace-provider-profile-tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: "100%", overflowY: "auto", width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3, pt: 0 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

export type TabConfig = {
  label: string[];
  component: any[]; // find correct type for all components
};

type TabProps = {
  config: TabConfig;
};

const MAX_TABS = 3;

const GenericTabs: React.FC<TabProps> = ({ config }: TabProps) => {
  const { classes } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("selectedTab");

  const [value, setValue] = React.useState<number>(
    selectedTab && Number(selectedTab) <= MAX_TABS ? Number(selectedTab) : 0,
  );
  const [tabChangedByUser, setTabChangedByUser] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabChangedByUser(true);
    setValue(newValue);
    setSearchParams({ selectedTab: newValue.toString() });
  };

  const tabCondition =
    selectedTab && Number(selectedTab) <= MAX_TABS && !tabChangedByUser
      ? Number(selectedTab)
      : value;

  return (
    <Grid container className={classes.root}>
      <Tabs
        value={tabCondition}
        onChange={handleChange}
        className={classes.tabsNav}
        data-testid="generic-tabs"
      >
        {config.label.map((label) => (
          <Tab label={label} key={label} />
        ))}
      </Tabs>
      {config.component.map((component, index) => (
        <TabPanel value={tabCondition} index={index} key={component}>
          {typeof component === "function" ? component() : component}
        </TabPanel>
      ))}
    </Grid>
  );
};

export default GenericTabs;
