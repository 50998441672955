import { makeStyles } from "tss-react/mui";

const defaultProps = {
  isLoading: false,
  showScannedSpecimenImage: false,
};

export const useStyles = makeStyles<{ isLoading?: boolean; showScannedSpecimenImage?: boolean }>()(
  (theme, props = defaultProps) => ({
    root: {
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "6px",
      padding: "24px",
      boxSizing: "border-box",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
      "& .MuiButton-root": {
        minWidth: props.showScannedSpecimenImage ? "40px" : "64px",
      },
      "& .MuiGrid-container": {
        flexWrap: props.showScannedSpecimenImage ? "unset" : "nowrap",
      },
      "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
        zIndex: 1,
      },
      "& .MuiGrid-spacing-xs-1 > .MuiGrid-item .MuiButton-label": {
        display: props.showScannedSpecimenImage ? "flex" : "inherit",
        flexDirection: props.showScannedSpecimenImage ? "column" : "inherit",
      },
      "& .MuiGrid-spacing-xs-1 > .MuiGrid-item .MuiButton-label .MuiButton-startIcon": {
        marginRight: props.showScannedSpecimenImage ? "0px" : "6px",
        "& svg": {
          fontSize: props.showScannedSpecimenImage ? "25px" : "18px",
        },
      },
      "& .MuiButton-containedSizeSmall": {
        fontSize: props.showScannedSpecimenImage ? "0.6rem" : "0.8125rem",
      },
    },
    rootMobile: {
      boxShadow: "none",
      padding: "0px",
      border: "none",
    },
    table: {
      minWidth: 750,
    },
    tableHeaderBackground: {
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "#d4d4d4",
        padding: "4px 8px",
      },
    },
    tableCellCollapse: {
      "& .MuiTableCell-root": {
        width: "9em",
        padding: "12px 8px",
      },
    },
    clearTagsButton: {
      margin: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    clickedTableRow: {
      backgroundColor: "rgba(0, 0, 0, 0.06)",

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    searchContainer: {
      width: "100%",
      maxWidth: "600px",
      marginBottom: "30px",
    },
    searchLabel: {
      marginBottom: "10px",
    },
    cellHead: {
      fontWeight: 600,
      whiteSpace: "nowrap",
    },
    unsortableCellHead: {
      borderBottom: "unset",
    },
    loadingCell: {
      height: "40vh",
      borderBottom: "unset",
      display: "flex",
      alignItems: "center",
    },
    loadingSpinner: {
      position: "absolute",
      margin: "auto",
      left: "0px",
      right: "0px",
    },
    tableContainer: {
      overflowX: props.isLoading ? "hidden" : undefined,
      height: "auto",
      maxHeight: "65vh",
    },
    tagsContainer: {
      display: "flex",
    },
    searchButton: {
      marginLeft: "5px",
    },
    checkbox: {
      [theme.breakpoints.down("md")]: {
        "& .MuiSvgIcon-root": {
          fontSize: "32px",
        },
      },
    },
    unsoratbleCellHead: {},
    customTooltip: {
      position: "relative",
      bottom: "3em",
    },
    hideCustomTooltip: {
      display: "none",
    },
    chip: {
      backgroundColor: "#043070",
      color: "white",
      margin: "0 4px 4px 0",
      fontSize: "12px",
    },
    calenderWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > div": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    formControl: {
      display: "flex",
      justifyContent: "center",
    },
    selectContainer: {
      "& > div": {
        minHeight: "32px",
        padding: "12px 24px 12px 14px",
      },
    },
    chipContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "2px",
    },
    commnsSentNotification: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "4px",
      "&:hover div": {
        display: "flex",
      },
      "& > span": {
        color: "#fff",
        fontSize: "12px",
        fontWeight: "bold",
        width: "16px",
        height: "16px",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "1px",
      },
    },
    commsHistoryWrapper: {
      flexDirection: "column",
      backgroundColor: "#043070",
      padding: "8px",
      borderRadius: "6px",
      position: "absolute",
      right: 0,
      top: "2em",
      display: "none",
      transition: ".2s",
      zIndex: 2,

      "& > p": {
        margin: 0,
        color: "#fff",
        textAlign: "center",
      },

      "& > figure": {
        width: "12px",
        height: "12px",
        background: "#043070",
        borderRadius: "2px",
        position: "absolute",
        top: "-4px",
        right: "32px",
        transform: "rotate(45deg)",
      },
    },
  }),
);
