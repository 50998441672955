import React from "react";
import DOTForm from "..";
import { Dashboard } from "./Steps/Dashboard";
import GenerateReportStep from "./Steps/GenerateReportStep";
import MedicalDeterminationStep from "./Steps/MedicalDeterminationStep";
import { DOT_MEDICALEXAMINER_STEPS, IData, StepChangeProps } from "../types/constants";

function getSteps() {
  return DOT_MEDICALEXAMINER_STEPS;
}

function getStepContent(
  step: number,
  data: IData,
  medicalRecords: Set<string> | undefined,
  onChange: (name: string, event: any) => void,
  handleStepChange?: (stepInfo: StepChangeProps) => void,
  formRef?: React.RefObject<HTMLFormElement>,
  loading?: boolean,
  setShouldDisableContinueButton?: (shouldDisableContinueButton: boolean) => void,
) {
  switch (step) {
    case 0:
      return (
        <Dashboard
          formData={data}
          medicalRecords={medicalRecords}
          handleChange={onChange}
          handleStepChange={handleStepChange}
          formRef={formRef}
          isLoading={loading}
        />
      );
    case 1:
      return (
        <MedicalDeterminationStep
          formData={data}
          handleChange={onChange}
          handleStepChange={handleStepChange}
          setShouldDisableContinueButton={setShouldDisableContinueButton}
        />
      );
    case 2:
      return <GenerateReportStep formData={data} handleChange={onChange} />;
    default:
      return "Unknown step";
  }
}

const DOTSubmissionPage = () => {
  const steps = getSteps();
  return <DOTForm getSubmissionComponentsSteps={getStepContent} getSubmissionSteps={steps} />;
};

export default DOTSubmissionPage;
