import { makeStyles } from "tss-react/mui";

export const useSpecimenStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: "1em auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    margin: "0 auto",
  },
  mobileContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    margin: "0 auto",
    paddingTop: "54px",
  },
  qrScannerContainer: {
    alignSelf: "center",
    width: "400px",
    height: "330px",
  },
  qrScannerContainerMobile: {
    alignSelf: "center",
    width: "320px",
    height: "250px",
    maxHeight: "250px",
    margin: ".5em 0",
  },
  locationInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 .5em 0",
    width: "100%",
    maxWidth: "470px",
  },
  autocompleteList: {
    overflowY: "visible",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#f3910da8",
      borderRadius: "10px",
      border: "2px solid white",
      backgroundClip: "padding-box",
    },
  },
  scannerContainer: {
    display: "flex",
    flexGrow: 1,
    marginLeft: "1em",
    marginRight: "1em",
    flexDirection: "column",
    overflow: "auto",
    minHeight: "74vh",
  },
  mobileFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "auto",
    left: "0",
    right: "0",
    bottom: "0",
  },
  footerBtn: {
    width: "48%",
    margin: "0.2px",
    height: "3rem",
  },
  footer: {
    bottom: ".5em",
    left: ".1em",
    right: ".1em",
    paddingBottom: "2em",
  },
  setPositionToInherit: {
    position: "inherit",
  },
  setPositionToFixed: {
    position: "fixed",
  },
  setBottomMargin: {
    marginBottom: "10em",
  },
  addManuallyBtn: {
    marginBottom: ".4em",
  },
  buttons: {
    maringBottom: "1em",
    justifyContent: "space-between",
  },
  mobileBtn: {
    width: "100%",
    marginTop: ".5em",
  },
  mobileBtnAddSpec: {
    width: "80%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "24px 0 8px 0",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
  scannerLocationTitle: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "8px",
    color: "#043070",
    width: "100%",
    textAlign: "center",
  },
  scanLocationTitle: {
    fontSize: "18px",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
  backButton: {
    alignItems: "center",
    background: "transparent",
    outline: "none",
    border: "none",
    color: "inherit",
    marginRight: "auto",
    position: "absolute",

    "&:focus": {
      outline: "none",
    },
  },
  textLabel: {
    display: "flex",
    fontSize: "16px",
    fontWeight: 600,
    color: "black",
    paddingRight: ".5em",
    paddingTop: "1em",
    noWrap: "true",
  },
  prefix: {
    position: "relative",
    top: "3px",
    marginRight: "4px",
  },
  textInput: {
    fontSize: "16px",
    padding: "3px 0",
    background: "#f5f5f5",
    appearance: "none",
    "&:disabled": {
      fontSize: "16px",
      color: "#043070",
      fontWeight: "500",
      position: "relative",
      top: "3px",
    },
  },
  formContainer: {
    background: "#f5f5f5",
    display: "flex",
    flex: 1,
  },
  expandFormButton: {
    width: "28px",
    height: "28px",
    position: "absolute",
    right: "1em",
    top: "1.3em",
    zIndex: 2,
  },
  formInfoContainer: {
    flexDirection: "row",
    textAlign: "left",
    justifyContent: "left",
    width: "100%",
  },
  textField: {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  inputTextField: {
    display: "flex",
    marginRight: "auto",
    alignSelf: "center",
    "& div > div > button:disabled": {
      opacity: 0,
    },
  },
  inputButton: {
    display: "flex",
    justifyContent: "right",
    marginLeft: "3px",
    maxWidth: "2px",
    minWidth: "2px",
    background: "yellow",
  },
  locationWrapper: {
    height: "87vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  locationSelector: {
    display: "flex",
    marginLeft: "1em",
    marginRight: "1em",
    flexDirection: "column",
    alignItems: "center",
  },
  pickFlowButtonWrapper: {
    display: "flex",
    margin: "auto",
    flexDirection: "column",
  },
  manuallyButtonContainer: {
    marginTop: "1em",
  },
  specimenTextInfo: {
    padding: ".25em",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    lineHeight: "1",
    fontSize: 18,
  },
  timelineContent: {
    marginBottom: "24px",
    padding: "0 0 0 16px",
  },
  timelineHeaderCard: {
    background: "linear-gradient(55deg, rgba(238,238,238,1) 80%, rgba(255,255,255,1) 70%)",
    textAlign: "left",
    padding: "4px 16px",
    width: "8em",
    fontWeight: "bold",
  },
  timelineCardContent: {
    textAlign: "left",
    padding: "20px 16px",
    position: "relative",
  },
  checkIcon: {
    color: "#28a745",
    fontSize: "2em",
  },
  timelineItem: {
    "&::before": {
      display: "none",
    },
  },
  timelineConector: {
    backgroundColor: "#F18F28",
    width: "5px",
    position: "relative",
    left: "10px",
    height: "2.5em",
  },
  timelineDot: {
    backgroundColor: "#F18F28",
    width: "24px",
    height: "24px",
    margin: "0px",
  },
  infoTitle: {
    letterSpacing: 0.15,
    fontSize: 18,
    lineHeight: 1.2,
  },
  infoText: {
    color: "#0B79D0",
    letterSpacing: 0.4,
    fontSize: 14,
    lineHeight: 1.6,
  },
  cardExpandIcon: {
    position: "absolute",
    color: "darkgrey",
    right: "0.5em",
    top: "1.5em",
    fontSize: 30,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 200,
    width: 520,
  },
  readerMobileBox: {
    width: "100%",
  },
  readerTabletBox: {
    width: "50%",
    minHeight: "100vh",
  },
  readerDesktopBox: {
    width: "40%",
    minHeight: "100vh",
  },
  confirmationModal: {
    justifyContent: "center",
    marginBottom: 24,
  },
  dialogTitle: {
    textAlign: "center",
    marginTop: 24,
  },
  dialog: {
    width: "100%",
    padding: "24px 0",
  },
  historyWrapper: {
    maxWidth: "650px",
    width: "100%",
  },
  codeCardWrapper: {
    gap: "16px",
    width: "100%",
    padding: "0 1em",
    marginBottom: "8px",
  },
  scannedCodesCard: {
    width: "100%",
    background: "#f5f5f5",
    padding: "4px 16px",
    borderRadius: "4px",
    transition: ".2s",
    cursor: "pointer",
    border: "1px solid #d8d7d7",
  },
  noData: {
    fontSize: "1.5em",
    color: "#B1B1B1",
    margin: "3em auto",
  },
  actionButtonsContainer: {
    gap: "8px",
    padding: "8px 16px",
    position: "fixed",
    bottom: "0",
    width: "100%",
    background: "white",
    maxWidth: "600px",
  },
  pickFlowButton: {
    marginBottom: "2em",
    fontSize: "18px",
    width: "16em",
    height: "3.5em",
  },
  titleButton: {
    fontSize: "16px!important",
    width: "7.5em",
    height: "56px",
  },
  scannedDetailBackdrop: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: 0,
    right: 0,
    background: "#00000038",
    opacity: 1,
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "90px 16px 16px 16px",
  },
  scannedDetailModal: {
    background: "#FFF",
    width: "100%",
    maxWidth: "360px",
    height: "auto",
    maxHeight: "530px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    padding: "18px",
    borderRadius: "4px",
  },
  scannedDetailText: {
    color: "#043070",
    textAlign: "left",
    "& span": {
      color: "#000",
      fontWeight: "800",
    },
  },
  carouselSpecimensHistory: {
    "& .carousel-slider": {
      maxHeight: "345px",
    },
    "& .slider": {
      gap: "16px",
    },
    "& .slide": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#d9d9d9",
    },
  },
  addPhotoButton: {
    background: "#d9d9d9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "8px",
    width: "100%",
    height: "100%",
    margin: 0,
    border: "none",
    minHeight: "240px",
  },

  soundToggleButton: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "8px",
    marginTop: "-56px",
    marginRight: "0",
  },

  soundToogleButtonMobile: {
    position: "absolute",
    top: "0",
    right: "0",
  },
}));

export const useSpecimenMobileListStyles = makeStyles()((theme) => ({
  calendarRoot: {
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
  },
  calendarLabel: {
    fontFamily: "inherit",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
  footerBtn: {
    backgroundColor: "#043070",
    width: "2em",
    height: "2em",
    position: "fixed",
    right: "0.5em",
    bottom: "2em",
    boxShadow: "4px 4px 5px 0px rgba(0,0,0,0.08)",
    "&:hover": {
      backgroundColor: "#043070",
    },
  },
  mobileWrapper: {
    padding: "0 .5em .5em .5em",
    width: "100%",
    display: "flex",
    gap: "12px",
  },
  cardsWrapper: {
    width: "100%",
    padding: "0 .5em",
  },
  specimenCard: {
    padding: "1em 1em .5em 1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "1em",
    borderTop: "0.4em solid #F3910D",
  },
  labelBig: {
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "left",
  },
  labelBold: {
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
  },
  labelMedium: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
  },
  labelSmall: {
    fontSize: "14px",
    textAlign: "left",
    lineHeight: "1.2",
    marginBottom: "4px",
  },
  search: {
    flexDirection: "column",
    padding: "0 .5em",
    gap: ".5em",
    maxWidth: "37em",
    justifyContent: "center",
    margin: "0 auto",
  },
  expandBtn: {
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  specimenSection: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0 12px",
    margin: ".5em 0 .5em .5em",
    borderLeft: "2px solid #B1B1B1",
    cursor: "pointer",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    background: "#fff",
    borderRadius: "24px",
    padding: "42px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "18px",
    "& p": {
      fontSize: "20px",
      textAlign: "center",
    },
    "& div": {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
    },
  },
  noData: {
    fontSize: "2em",
    color: "#B1B1B1",
    margin: "3em auto",
  },
  filtersPaper: {
    margin: "auto",
    padding: "1em",
    position: "absolute",
    width: "85%",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  filtersContent: {
    flex: "1",
    padding: "1em",
  },
  dateFilterButton: {
    background: "#f0f0f0",
    fontSize: "14px",
  },
  titleContainer: {
    width: "100%",
    position: "relative",
  },
  listTitle: {
    fontSize: "18px",
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
  backButton: {
    position: "absolute",
    left: 0,
    padding: 0,
  },
  userSpecimensToggleWrapper: {
    width: "100%",
    height: "2.5em",
    padding: "0 8px",

    "& .MuiToggleButton-root": {
      textTransform: "capitalize",
      color: "#043070",
      width: "100%",
    },

    "& .Mui-selected": {
      background: "#e0e0e075",
    },
    "& .Mui-selected:hover": {
      background: "#e0e0e075",
    },

    "& button > span": {
      color: "#043070",
    },
  },
  externalIdIcon: {
    marginTop: "1rem",
    marginLeft: "0.4rem",
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
  datePickerAdornment: {
    paddingRight: "20px",
  },
}));
