import React, { useState } from "react";
import { useParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

interface FadSectionProps {
  title: string;
  aemUrl: string;
  publishedPageUrl: string;
  enable?: boolean;
  dataType?: string;
}

const FadSection: React.FC<FadSectionProps> = ({
  title,
  aemUrl,
  publishedPageUrl,
  enable,
  dataType,
}: FadSectionProps) => {
  const app = useApp();
  const { providerId } = useParams<{ providerId: string }>();
  const { locationId } = useParams<{ locationId: string }>();
  const [isToggled, setIsToggled] = useState(enable);
  const [isEditing, setIsEditing] = useState(false);

  const handleToggle = () => {
    setIsToggled((prev) => !prev);

    if (isEditing) {
      return;
    }

    const patchProviderData = async () => {
      setIsEditing(true);
      try {
        await axiosClient.patch(`${API_ENDPOINTS.dataCurationProviders}${providerId}`, {
          showInPMC: !isToggled,
        });
        app.addInfoMsg("Provider updated successfully");
      } catch (error) {
        app.addError("Error updating provider. Please try again.");
      } finally {
        setIsEditing(false);
      }
    };

    const patchLocationData = async () => {
      setIsEditing(true);
      try {
        await axiosClient.patch(`${API_ENDPOINTS.dataCurationLocations}/${locationId}`, {
          locationFinder: !isToggled,
        });
        app.addInfoMsg("Location updated successfully");
      } catch (error) {
        app.addError("Error updating location. Please try again.");
      } finally {
        setIsEditing(false);
      }
    };

    if (dataType === "location") {
      patchLocationData();
    } else if (dataType === "provider") {
      patchProviderData();
    }
  };

  return (
    <div style={{ display: "flex", margin: "20px", marginLeft: "auto" }} data-testid="fad-section">
      <FormControlLabel
        control={<Switch checked={isToggled} onChange={handleToggle} />}
        label={title}
      />
      <div>
        <Button
          variant="outlined"
          disabled={!isToggled}
          endIcon={<OpenInNewIcon />}
          style={{ marginRight: "1rem" }}
          onClick={() => window.open(aemUrl, "_blank")}
        >
          AEM
        </Button>
        <Button
          variant="outlined"
          disabled={!isToggled}
          endIcon={<OpenInNewIcon />}
          style={{ marginRight: "4px" }}
          onClick={() => window.open(publishedPageUrl, "_blank")}
        >
          View published page
        </Button>
      </div>
    </div>
  );
};

export default FadSection;
