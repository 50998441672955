import React, { useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import useTranslation from "hooks/useTranslation";
import { ProviderClinical, TabPaneProps } from "../../types";

const ClinicalSection: React.FC<TabPaneProps> = ({ shouldDisableForm }: TabPaneProps) => {
  const {
    web: {
      dataCuration: {
        providerProfile: {
          clinical: {
            clinical: {
              practiceTypeTitle,
              providerTypeTitle,
              primarySpecialityTitle,
              primarySpecialityHelperText,
              claimedSpecialityHelperText,
              secondarySpecialityTitle,
              specialtyServicesLabel,
              claimedSpecialityTitle,
            },
          },
        },
      },
    },
  } = useTranslation();

  const {
    providerClinical: { clinical },
    setCurrentPayload,
  } = useProviderContext();

  const primarySpecialties = clinical.specialties
    .filter((specialty) => specialty.isPrimarySpecialty)
    .map((item) => ({ id: item.specialty.id, name: item.specialty.name }));

  const secondarySpecialties = clinical.specialties
    .filter((item) => !item.isPrimarySpecialty)
    .map((item) => ({ id: item.specialty.id, name: item.specialty.name }));

  const [formData, setFormData] = useState({
    ...clinical,
  });

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value || e;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== clinical[key]) {
      setCurrentPayload((prev: ProviderClinical) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const handleCheckboxChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData({
      ...formData,
      [key]: checked,
    });

    setCurrentPayload((prev: ProviderClinical) => ({
      ...(prev || {}),
      [key]: checked,
    }));
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      padding="16px 0 42px 0"
      data-testid="clinical-section"
    >
      <Grid item xs={12} md={12}>
        <div style={{ textAlign: "start" }}>
          <h5>{practiceTypeTitle}</h5>
        </div>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "0.5rem",
          }}
          data-testid="practiceType"
        >
          <FormControlLabel
            control={
              <Checkbox
                data-cy="Primary Care-cyName"
                data-testid="Primary Care"
                checked={formData.primaryCareProvider}
                name="Primary Care"
                disabled={shouldDisableForm}
                onChange={handleCheckboxChange("primaryCareProvider")}
              />
            }
            label="Primary Care"
          />
          <FormControlLabel
            control={
              <Checkbox
                data-cy="Specialty Care-cyName"
                data-testid="Specialty Care"
                checked={formData.specialtyProvider ? formData.specialtyProvider : ""}
                name="Specialty Care"
                disabled={shouldDisableForm}
                onChange={handleCheckboxChange("specialtyProvider")}
              />
            }
            label="Specialty Care"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="systemType-label" disabled={shouldDisableForm}>
            {providerTypeTitle}
          </InputLabel>
          <Select
            data-testid="clinical-section-provider-types-systemType"
            labelId="systemType"
            label={providerTypeTitle}
            value={clinical.providerType || ""}
            disabled={shouldDisableForm}
            onChange={(e) => handleChange("systemType", e.target.value as string)}
            style={{ textAlign: "start" }}
          >
            <MenuItem value={clinical.providerType}>{clinical.providerType}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            options={primarySpecialties.length ? primarySpecialties : []}
            getOptionLabel={(option) => option.name || ""}
            value={primarySpecialties.length ? primarySpecialties : []}
            onChange={(newValue) => handleChange("primarySpecialty", newValue)}
            disabled={shouldDisableForm}
            renderInput={(params) => (
              <TextField
                {...params}
                label={primarySpecialityTitle}
                variant="standard"
                helperText={primarySpecialityHelperText}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            data-testid="clinical-section-provider-types-autocomplete"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="clinical-section-provider-types-autocomplete-2"
            data-testid="clinical-section-provider-types-autocomplete-2"
            disabled
            options={secondarySpecialties.length ? secondarySpecialties : []}
            value={secondarySpecialties.length ? secondarySpecialties : []}
            onChange={(newValue) => handleChange("secondarySpecialty", newValue)}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={secondarySpecialityTitle}
                placeholder="Select Provider Type"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="clinical-section-claimed-specialities-autocomplete"
            data-testid="clinical-section-claimed-specialities-autocomplete"
            disabled={shouldDisableForm}
            options={clinical?.claimedSpecialties?.length ? clinical.claimedSpecialties : []}
            defaultValue={clinical?.claimedSpecialties?.length ? clinical.claimedSpecialties : []}
            onChange={(event, newValue) =>
              handleChange("claimedSpecialties", { target: { value: newValue } })
            }
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={claimedSpecialityTitle}
                placeholder="Select Claimed Specialties"
                helperText={claimedSpecialityHelperText}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="clinical-section-provider-specialtyServices"
            data-testid="clinical-section-provider-specialtyServices"
            disabled
            options={clinical?.specialtyServices?.length ? clinical.specialtyServices : []}
            value={clinical?.specialtyServices?.length ? clinical.specialtyServices : []}
            onChange={(newValue) => handleChange("specialityService", newValue)}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={specialtyServicesLabel}
                placeholder="Select Specialty Services"
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ClinicalSection;
