import React, { useState } from "react";
import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useStyles } from "../../utils/styles";
import { Delete as DeleteIcon } from "@mui/icons-material";

export const timePickers = [
  { name: "openTime", label: "Start Time" },
  { name: "closeTime", label: "End Time" },
  { name: "lunchStartTime", label: "Break Start" },
  { name: "lunchEndTime", label: "Break End" },
];

const AddAnotherAdjustHours = ({
  setStartTime,
  adjustedHoursEffectiveStart,
  setAdjustedHoursEffectiveStart,
  setEndTime,
  setBreakStartTime,
  setBreakEndTime,
  overlapError,
  setAdjustedHoursMessage,
  setAddNewAdjustedHours,
  addNewAdjustedHours,
}) => {
  const { classes } = useStyles();
  const [isButtonClicked, setIsButtonClicked] = useState(true);

  function formatTime(startTimeString) {
    const date = new Date(startTimeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    return formattedTime;
  }

  const formatDate = (date) => {
    const newDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;
    setAdjustedHoursEffectiveStart(newDate);
  };

  const onChangeStartTime = (startTimeString) => {
    const startTime = formatTime(startTimeString);
    setStartTime(startTime);
  };

  const onChangeEndTime = (endTimeString) => {
    const endTime = formatTime(endTimeString);
    setEndTime(endTime);
  };

  const onChangeBreakStart = (breakStartString) => {
    const breakStartTime = formatTime(breakStartString);
    setBreakStartTime(breakStartTime);
  };

  const onChangeBreakEnd = (breakEndString) => {
    const breakEndTime = formatTime(breakEndString);
    setBreakEndTime(breakEndTime);
  };

  const handleDelete = () => {
    setAddNewAdjustedHours(false);
    setIsButtonClicked(false);
  }

  return (
    <Box marginTop="24px" display="flex" flexDirection="column">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div key="time-hour">
          <Box className={classes.daysWrapper} style={{ borderBottom: "none" }}>
            <DatePicker
              label="Date"
              minDate={new Date()}
              value={new Date(adjustedHoursEffectiveStart)}
              onChange={(date) => formatDate(date)}
              slotProps={{
                textField: {
                  error: overlapError,
                  style: {
                    borderColor: overlapError ? "red" : "",
                  },
                },
              }}
            />
            <TimePicker key="openTime" label="Start Time" onChange={(startTimeString) => onChangeStartTime(startTimeString)} />
            <TimePicker key="openTime" label="End Time" onChange={(endTimeString) => onChangeEndTime(endTimeString)} />
            <TimePicker key="openTime" label="Break Start" onChange={(breakStartString) => onChangeBreakStart(breakStartString)} />
            <TimePicker key="openTime" label="Break End" onChange={(breakEndString) => onChangeBreakEnd(breakEndString)} />
          </Box>
          <Grid container style={{ paddingBottom: "1.5em", borderBottom: "1px solid #f3f3f3" }}>
            <Grid paddingTop="24px" xs={9} md={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  error={false}
                  id="adjustedHoursMessaging"
                  name="adjustedHoursMessaging"
                  size="medium"
                  variant="outlined"
                  label="Messaging"
                  helperText={false}
                  data-cy="Practice-adjustedHoursMessaging"
                  data-testid="Practice-adjustedHoursMessaging"
                  onChange={(event) => setAdjustedHoursMessage(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2.4} md={2.4} paddingTop="24px">
              {isButtonClicked && (
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  color="primary"
                  style={{ width: "160px", height: "40px", marginTop: "6px" }}
                  startIcon={<DeleteIcon />}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
          
        </div>
      </LocalizationProvider>
    </Box>
  );
};

export default AddAnotherAdjustHours;
