import React, { useState } from "react";
import { Box, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLocationContext } from "../../LocationContext";
import { format } from "date-fns";
import { days, parseTime, timePickers, weekdays, weekends } from "../../utils/consts";
import { useStyles } from "../../utils/styles";

const DaysTimePickers = ({ isEditing }) => {
  const { classes } = useStyles();
  const {
    locationData: { hoursAndSchedule },
    setCurrentPayload,
  } = useLocationContext();

  const [open24HoursValue, setOpen24HoursValue] = useState(hoursAndSchedule.open24Hours);
  const [temporarilyClosedValue, setTemporarilyClosedValue] = useState(
    hoursAndSchedule.temporarilyClosed,
  );

  const [checkboxes, setCheckboxes] = useState(
    days.concat("weekdays", "weekends").reduce((acc, day) => ({ ...acc, [day]: false }), {}),
  );
  const [times, setTimes] = useState(
    days.concat("weekdays", "weekends").reduce(
      (acc, day) => ({
        ...acc,
        [day]: {
          openTime: parseTime(hoursAndSchedule[day]?.openTime),
          closeTime: parseTime(hoursAndSchedule[day]?.closeTime),
          lunchStartTime: parseTime(hoursAndSchedule[day]?.lunchStartTime),
          lunchEndTime: parseTime(hoursAndSchedule[day]?.lunchEndTime),
        },
      }),
      {},
    ),
  );

  const getMinTime = (day, pickerName) => {
    let minTime = null;
    if (day === "weekdays") {
      const timesForWeekdays = weekdays.map((weekday) => times[weekday]);
      minTime = timesForWeekdays.reduce((min, current) => {
        if (
          pickerName === "closeTime" &&
          current.openTime &&
          (min === null || current.openTime < min)
        ) {
          return current.openTime;
        }
        if (
          pickerName === "lunchEndTime" &&
          current.lunchStartTime &&
          (min === null || current.lunchStartTime < min)
        ) {
          return current.lunchStartTime;
        }
        return min;
      }, null);
    } else if (day === "weekends") {
      const timesForWeekends = weekends.map((weekendDay) => times[weekendDay]);
      minTime = timesForWeekends.reduce((min, current) => {
        if (
          pickerName === "closeTime" &&
          current.openTime &&
          (min === null || current.openTime < min)
        ) {
          return current.openTime;
        }
        if (
          pickerName === "lunchEndTime" &&
          current.lunchStartTime &&
          (min === null || current.lunchStartTime < min)
        ) {
          return current.lunchStartTime;
        }
        return min;
      }, null);
    } else {
      const timesForDay = times[day];
      switch (pickerName) {
        case "closeTime":
          minTime = timesForDay.openTime || null;
          break;
        case "lunchEndTime":
          minTime = timesForDay.lunchStartTime || null;
          break;
        default:
          break;
      }
    }
    return minTime;
  };

  const handleSwitchChange = (switchName, value) => {
    if (switchName === "open24Hours") {
      setOpen24HoursValue(value);

      if (value) {
        setTimes((prevTimes) =>
          days.reduce((acc, day) => {
            acc[day] = {
              openTime: null,
              closeTime: null,
              lunchStartTime: null,
              lunchEndTime: null,
            };
            return acc;
          }, {}),
        );

        setCurrentPayload((prevPayload) => {
          const filteredDays = days.filter((day) => day !== "weekdays" && day !== "weekends");

          return {
            ...prevPayload,
            hoursAndSchedule: {
              ...(prevPayload?.hoursAndSchedule || {}),
              open24Hours: true,
              ...filteredDays.reduce((acc, day) => {
                acc[day] = {
                  openTime: null,
                  closeTime: null,
                  lunchStartTime: null,
                  lunchEndTime: null,
                };
                return acc;
              }, {}),
            },
          };
        });
      } else {
        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          hoursAndSchedule: {
            ...(prevPayload?.hoursAndSchedule || {}),
            open24Hours: false,
          },
        }));
      }
    } else if (switchName === "temporarilyClosed") {
      setTemporarilyClosedValue(value);
      setCurrentPayload((prevPayload) => ({
        ...prevPayload,
        hoursAndSchedule: {
          ...(prevPayload?.hoursAndSchedule || {}),
          temporarilyClosed: value,
        },
      }));
    }
  };

  const handleTimeChange = (day, pickerName, newValue) => {
    const formattedTime = newValue ? format(newValue, "HH:mm:ss") : null;

    setTimes((prev) => {
      const newTimes = { ...prev };

      if (day === "weekdays") {
        weekdays.forEach((weekday) => {
          newTimes[weekday][pickerName] = newValue;
        });
      } else if (day === "weekends") {
        weekends.forEach((weekendDay) => {
          newTimes[weekendDay][pickerName] = newValue;
        });
      } else {
        newTimes[day][pickerName] = newValue;
      }

      return newTimes;
    });

    setOpen24HoursValue(false);

    setCurrentPayload((prevPayload) => {
      const updatedPayload = {
        ...prevPayload,
        hoursAndSchedule: {
          ...(prevPayload?.hoursAndSchedule || {}),
          open24Hours: false,
        },
      };

      if (day === "weekdays") {
        weekdays.forEach((weekday) => {
          updatedPayload.hoursAndSchedule[weekday] = {
            ...(prevPayload?.hoursAndSchedule?.[weekday] || {}),
            [pickerName]: formattedTime,
          };
        });
      } else if (day === "weekends") {
        weekends.forEach((weekendDay) => {
          updatedPayload.hoursAndSchedule[weekendDay] = {
            ...(prevPayload?.hoursAndSchedule?.[weekendDay] || {}),
            [pickerName]: formattedTime,
          };
        });
      } else {
        updatedPayload.hoursAndSchedule[day] = {
          ...(prevPayload?.hoursAndSchedule?.[day] || {}),
          [pickerName]: formattedTime,
        };
      }

      return updatedPayload;
    });
  };

  const handleCheckboxChange = (day) => {
    setCheckboxes((prev) => {
      const newCheckboxes = { ...prev };

      if (day === "weekdays") {
        const isChecked = !prev[day];
        weekdays.forEach((weekday) => {
          newCheckboxes[weekday] = isChecked;
        });
      } else if (day === "weekends") {
        const isChecked = !prev[day];
        weekends.forEach((weekendDay) => {
          newCheckboxes[weekendDay] = isChecked;
        });
      }

      newCheckboxes[day] = !prev[day];
      return newCheckboxes;
    });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" marginTop="16px">
        <FormControlLabel
          className={classes.switch}
          disabled={!isEditing}
          control={
            <Switch
              color="secondary"
              checked={open24HoursValue}
              onChange={() => handleSwitchChange("open24Hours", !open24HoursValue)}
              name="open24Hours"
            />
          }
          label="Open 24 hours"
        />
        <FormControlLabel
          className={classes.switch}
          disabled={!isEditing}
          control={
            <Switch
              color="secondary"
              checked={temporarilyClosedValue}
              onChange={() => handleSwitchChange("temporarilyClosed", !temporarilyClosedValue)}
              name="temporarilyClosed"
            />
          }
          label="Temporarily Closed"
        />
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {days.map((day) => (
          <Box key={day} className={classes.daysWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes[day]}
                  onChange={() => handleCheckboxChange(day)}
                  color="primary"
                />
              }
              disabled={!isEditing}
              style={{ margin: 0, minWidth: "8em" }}
              label={day.charAt(0).toUpperCase() + day.slice(1)}
            />
            {timePickers.map((picker) => (
              <TimePicker
                key={picker.name}
                label={picker.label}
                value={times[day][picker.name]}
                onChange={(newValue) => handleTimeChange(day, picker.name, newValue)}
                disabled={!checkboxes[day] || !isEditing}
                minTime={getMinTime(day, picker.name)}
              />
            ))}
          </Box>
        ))}
      </LocalizationProvider>
    </Box>
  );
};

export default DaysTimePickers;
