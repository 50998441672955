import React from "react";
import { Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { LocationData } from "./LocationEditPage/utils/types";

const useStyles = makeStyles()((theme) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginLeft: "auto",
    width: "64px",
    height: "40px",
    marginRight: "10px",
  },
}));

interface EditableComponentProps {
  title: string;
  onEdit?: (slug: string) => Promise<void> | any; // Fix this any type when add the Provider handleEdit function.
  children: React.ReactElement | React.ReactNode;
  customAction?: React.ReactElement | React.ReactNode;
  showActionButton?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  disableSection?: boolean;
  onCancel?: () => void;
  currentPayload?: LocationData | null;
}

const Wrapper = ({ children }) => (
  <div style={{ display: "flex", marginLeft: "auto" }}>{children}</div>
);

const ActionsProvider = ({
  customAction,
  showActionButton,
  classes,
  onEdit,
  isEditing,
  isLoading,
  disableSection,
  currentPayload,
}) => {
  const editButtonText = isEditing ? "Save" : "Edit";

  const editAction = (
    <Button
      variant={isEditing ? "contained" : "outlined"}
      color="primary"
      className={classes.button}
      onClick={onEdit}
      disabled={disableSection || isLoading || (isEditing && !currentPayload)}
    >
      {isLoading ? (
        <CircularProgress color="inherit" style={{ width: "22px", height: "22px" }} />
      ) : (
        editButtonText
      )}
    </Button>
  );

  if (!customAction && !showActionButton) {
    return null;
  }

  if (customAction && showActionButton) {
    return (
      <Wrapper>
        <>
          <div>{customAction}</div>
          <div style={{ marginLeft: "10px" }}>{editAction}</div>
        </>
      </Wrapper>
    );
  }

  if (customAction && !showActionButton) {
    return <Wrapper>{customAction}</Wrapper>;
  }

  return <Wrapper>{editAction}</Wrapper>;
};

const CancelProvider = ({
  customAction,
  showActionButton,
  classes,
  isLoading,
  disableSection,
  onCancel,
}) => {
  const cancelAction = (
    <Button
      variant="outlined"
      color="primary"
      className={classes.button}
      onClick={onCancel}
      disabled={disableSection || isLoading}
    >
      Cancel
    </Button>
  );

  if (!customAction && !showActionButton) {
    return null;
  }

  if (customAction && showActionButton) {
    return (
      <Wrapper>
        <>
          <div>{customAction}</div>
          <div style={{ marginLeft: "10px" }}>{cancelAction}</div>
        </>
      </Wrapper>
    );
  }

  if (customAction && !showActionButton) {
    return <Wrapper>{customAction}</Wrapper>;
  }

  return <Wrapper>{cancelAction}</Wrapper>;
};

const EditableComponent: React.FC<EditableComponentProps> = ({
  title,
  onEdit,
  children,
  customAction,
  showActionButton = true,
  isEditing,
  isLoading,
  disableSection = false,
  onCancel,
  currentPayload,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Grid>
        <div className={classes.titleWrapper}>
          <Typography
            variant="h6"
            className={classes.title}
            color={disableSection ? "gray" : "default"}
          >
            {title}
          </Typography>

          <Wrapper>
            <>
              <CancelProvider
                customAction={customAction}
                showActionButton={showActionButton}
                classes={classes}
                isLoading={isLoading}
                disableSection={disableSection}
                onCancel={onCancel}
              />
              <ActionsProvider
                currentPayload={currentPayload}
                customAction={customAction}
                showActionButton={showActionButton}
                classes={classes}
                onEdit={onEdit}
                isEditing={isEditing}
                isLoading={isLoading}
                disableSection={disableSection}
              />
            </>
          </Wrapper>
        </div>
        <Divider />
      </Grid>
      {children}
    </>
  );
};

export default EditableComponent;
