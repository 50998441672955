import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Chip, Grid, TextField, Typography } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useStyles } from "../../../utils/styles";
import { TabPaneProps } from "../../types";

type InsuranceType = {
  id: number;
  name: string;
};

const InsuranceAcceptedSection: React.FC<TabPaneProps> = ({ shouldDisableForm, cancel }) => {
  const { classes } = useStyles();
  const {
    providerGeneralInfoAndLocation: { insuranceAccepted },
    setCurrentPayload,
  } = useProviderContext();

  const [insurancesList, setInsurancesList] = useState<InsuranceType[]>(insuranceAccepted || []); // Will replace for the list of all insurances available.
  const [autocompleteValue, setAutocompleteValue] = useState<InsuranceType | null>(null);
  const [insurances, setInsurances] = useState<InsuranceType[]>(insuranceAccepted || []);

  useEffect(() => setAutocompleteValue(null), [cancel]);

  const handleChangeInsurances = (event: React.SyntheticEvent, value: InsuranceType | null) => {
    if (value && !insurances.some((condition) => condition.id === value.id)) {
      setInsurances([...insurances, value]);

      setCurrentPayload((prev) => ({
        insuranceAccepted: [
          ...(prev?.insuranceAccepted || []),
          {
            ...value,
          },
        ],
      }));
    }

    setAutocompleteValue(value);
  };

  const handleRemoveInsurance = (id: number) => {
    const getInsurance = insurances.find((insurance) => insurance.id === id);
    setInsurances(insurances.filter((insurance) => insurance.id !== id));
    setCurrentPayload((prev) => ({
      insuranceAccepted: [
        ...(prev?.insuranceAccepted || []),
        {
          ...getInsurance,
          deleted: true,
        },
      ],
    }));
    setAutocompleteValue(null);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      padding="24px 16px"
      data-testid="insuranceAcceptedSection"
    >
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={insurancesList || []}
        value={autocompleteValue}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Search Insurances" />}
        onChange={handleChangeInsurances}
        disabled={shouldDisableForm}
        classes={{ paper: classes.autocompletePaper }}
        fullWidth
        style={{
          margin: "24px 0 16px 0",
          width: "100%",
          maxWidth: "416px",
        }}
      />

      <Box className={classes.insurancesList_wrapper}>
        {insurances.length === 0 ? (
          <Typography color="gray">No insurance selected</Typography>
        ) : (
          insurances.map(({ id, name }) => (
            <Chip
              key={id}
              label={name}
              onDelete={() => handleRemoveInsurance(id)}
              disabled={shouldDisableForm}
              style={{ padding: "8px 0" }}
            />
          ))
        )}
      </Box>
    </Grid>
  );
};

export default InsuranceAcceptedSection;
