import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import SpecimenTrackingFilters from "../Filters";
import EditSpecimenModal from "./EditSpecimenModal";
import SpecimenImagesDesktopModal from "./SpecimenImagesDesktopModal";
import { format } from "date-fns";
import { makeStyles } from "tss-react/mui";
import GenericEnhancedTable, { defaultGridConfig } from "components/GenericEnhancedTable";
import { permissions } from "components/shared/permissions";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import ToggleButtonsFilter from "views/SpecimenTracking/components/ReportFilter/ToggleButtonsFilter";
import { useDeleteSpecimen } from "hooks/specimenTracking/useDeleteSpecimen/useDeleteSpecimen";
import useTranslation from "hooks/useTranslation";
import { useUserType } from "hooks/useUserType/useUserType";
import { colDefs } from "../../utils/definitionColumnsList";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { SpecimenInfo, SpecimenTrackingListDesktopProps } from "./types";
import { UrlParamType } from "views/SpecimenTracking/types";

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listStyles: {
    listStyle: "none",
    "& li": {
      fontSize: "14px",
      "& span": {
        fontWeight: "bold",
        textTransform: "capitalize",
      },
    },
  },
  toggleButtonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  specimenImagesModal: {
    "& > .MuiDialog-container > .MuiDialog-paperWidthSm": {
      maxWidth: "900px",
      width: "100%",
    },
  },
}));

interface SpecimenImageInfo {
  MRN: string;
  "order Id": string;
  "specimen Id": string;
  "first Name": string;
  "last Name": string;
  "date of birth": string;
  "specimen Type": string;
  "specimen Info": string;
  specimenImage: string;
  specimenSpecimenImage: string;
}

export const SpecimenTrackingListDesktop = ({
  titleButtons,
  titleSelects,
}: Readonly<SpecimenTrackingListDesktopProps>) => {
  const [selectedSpecimen, setSelectedSpecimen] = useState<SpecimenInfo | null>(null);
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { addError, addInfoMsg } = useApp();
  const {
    web: { specimenTracking },
  } = useTranslation();
  const { deleteSpecimen } = useDeleteSpecimen();
  const { isAdmin, isManager, isSpecimenTech } = useUserType();
  const auth = useAuth();
  const userId = auth?.user.userId;
  const url = API_ENDPOINTS.specimenTrackingGetLast;
  const currentDate = format(new Date(), "MM/dd/yyyy");

  const initialStatus = sessionStorage.getItem("status") ?? "ALL";
  const initialCollectedBy = sessionStorage.getItem("collectedBy") ?? "ALL";
  const initialSearchBy = sessionStorage.getItem("searchBy") ?? "collectedDatetime";
  const initialSearchValue = sessionStorage.getItem("searchValue") ?? currentDate;

  const [sourceDataRefresher, setRefresher] = useState(false);
  const [openEditSpecimenModal, setOpenEditSpecimenModal] = useState<boolean>(false);
  const [selectedSpecimenData, setSelectedSpecimenData] = useState<SpecimenImageInfo | null>(null);
  const [urlParams, setUrlParams] = useState<UrlParamType>({
    search_by: initialSearchBy,
    search_text: initialSearchValue,
    ...(initialStatus && initialStatus !== "ALL" ? { status: initialStatus } : {}),
    ...(initialCollectedBy !== "ALL" && { collected_by: userId }),
  });

  const [status, setStatus] = useState<string>(initialStatus);
  const [collectedBy, setCollectedBy] = useState<string>(initialCollectedBy);
  const [searchBy, setSearchBy] = useState<string>(initialSearchBy);
  const [searchValue, setSearchValue] = useState<string>(initialSearchValue);

  const goToHistory = (row) => {
    navigate(`/console/${permissions.specimenTracking}/history/${row.specimenId}`);
  };

  const removeKeyParams = (key: keyof UrlParamType) => {
    setUrlParams((current: UrlParamType) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: toDelete, ...rest } = current;
      return rest;
    });
  };

  const handleChangeStatus = (event, newSelection) => {
    setStatus(newSelection);
    sessionStorage.setItem("status", newSelection);
    if (!newSelection || newSelection === "ALL") {
      removeKeyParams("status");
    } else {
      setUrlParams((prevParams) => ({ ...prevParams, status: newSelection }));
    }
  };

  const handleChangeMySpecimens = (event, newSelection) => {
    setCollectedBy(newSelection);
    sessionStorage.setItem("collectedBy", newSelection);
    if (newSelection === "ALL") {
      removeKeyParams("collected_by");
    } else {
      setUrlParams((prevParams) => ({ ...prevParams, collected_by: userId }));
    }
  };

  const handleSearch = () => {
    sessionStorage.setItem("searchValue", searchValue);
    setUrlParams((prevParams) => ({
      ...prevParams,
      search_by: searchBy,
      search_text: searchValue,
    }));
  };

  const clearFilters = () => {
    if (searchBy === "collectedDatetime") {
      setSearchValue(currentDate);
    } else {
      setSearchValue("");
    }
    sessionStorage.setItem("searchValue", "");
    setUrlParams({ ...urlParams, search_text: "" });
  };

  const handleDeleteSpecimen = useCallback(
    async (row) => {
      // eslint-disable-next-line no-alert
      if (!window.confirm(`Are you sure do you want to delete the Specimen ${row.specimenId}?`)) {
        return false;
      }
      try {
        await deleteSpecimen(row.specimenId);
        addInfoMsg("Specimen has been deleted successfully.");
        setRefresher((prev) => !prev);
      } catch {
        addError("Problem deleting specimen, please contact support.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addError, addInfoMsg],
  );

  const handleEditSpecimen = useCallback((specimen) => {
    setOpenEditSpecimenModal(true);
    setSelectedSpecimen(specimen);
  }, []);

  const onShowScannedSpecimenImageHandler = useCallback((row) => {
    const {
      specimenOrderMrn,
      specimenOrderOrderExternalId,
      specimenSpecimenExternalId,
      specimenOrderPatientFirstName,
      specimenOrderPatientLastName,
      specimenOrderDob,
      specimenSpecimenType,
      specimenDescription,
      specimenImage,
      specimenSpecimenImage,
    } = row;

    /* eslint-disable object-shorthand */
    setSelectedSpecimenData({
      MRN: specimenOrderMrn,
      "order Id": specimenOrderOrderExternalId,
      "specimen Id": specimenSpecimenExternalId,
      "first Name": specimenOrderPatientFirstName,
      "last Name": specimenOrderPatientLastName,
      "date of birth": specimenOrderDob,
      "specimen Type": specimenSpecimenType,
      "specimen Info": specimenDescription,
      specimenImage: specimenImage,
      specimenSpecimenImage: specimenSpecimenImage,
    });
  }, []);

  const handleCloseEditSpecimenModal = () => {
    setOpenEditSpecimenModal(false);
  };

  const handleSpecimenSaved = () => {
    setRefresher((prev) => !prev);
    setOpenEditSpecimenModal(false);
  };

  useEffect(() => {
    sessionStorage.setItem("searchBy", searchBy);
  }, [searchBy]);

  return (
    <>
      {openEditSpecimenModal && (
        <EditSpecimenModal
          isOpen={openEditSpecimenModal}
          handleClose={handleCloseEditSpecimenModal}
          handleSaved={handleSpecimenSaved}
          specimen={{
            specimenSpecimenExternalId: selectedSpecimen?.specimenSpecimenExternalId ?? "",
            specimenId: selectedSpecimen?.specimenId ?? 0,
          }}
        />
      )}
      <Grid container style={{ padding: "0 5%" }} justifyContent="center">
        <Typography variant="h5" style={{ marginBottom: "16px", fontWeight: "bold" }}>
          {specimenTracking.listTitle}
        </Typography>
        <GenericEnhancedTable
          gridConfig={{
            ...defaultGridConfig,
            showSearchBar: false,
            showActionColumn: true,
            allowDelete: isAdmin || isSpecimenTech || isManager,
            allowEdit: isAdmin || isManager || isSpecimenTech,
            allowHistory: true,
            showScannedSpecimenImage: true,
          }}
          /* @ts-ignore-next-line */
          gridType="Available"
          colDefs={colDefs}
          orderBy="collectedDatetime"
          order="desc"
          titleButtons={titleButtons}
          titleSelects={titleSelects}
          url={url}
          urlParams={urlParams}
          hideCheckboxes
          onDeleteHandler={handleDeleteSpecimen}
          onEditHandler={handleEditSpecimen}
          onHistoryHandler={goToHistory}
          refreshSourceData={sourceDataRefresher}
          onShowScannedSpecimenImageHandler={onShowScannedSpecimenImageHandler}
          customFilter={
            <SpecimenTrackingFilters
              searchBy={searchBy}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setSearchBy={setSearchBy}
              handleSearch={handleSearch}
              clearFilters={clearFilters}
            />
          }
          toolbarDynamicContent={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ gap: "16px", height: "56px" }}
            >
              <ToggleButtonsFilter
                onChange={handleChangeMySpecimens}
                value={collectedBy}
                filterBy="collectedBy"
              />
              <ToggleButtonsFilter onChange={handleChangeStatus} value={status} filterBy="status" />
            </Box>
          }
        />
        <SpecimenImagesDesktopModal
          selectedSpecimenData={selectedSpecimenData}
          classes={classes}
          onClose={() => setSelectedSpecimenData(null)}
        />
      </Grid>
    </>
  );
};

export default SpecimenTrackingListDesktop;
