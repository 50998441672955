import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ readOnly: boolean }>()((theme, props) => ({
  app: {
    "& .ql-container": {
      borderBottomLeftRadius: "0.3em",
      borderBottomRightRadius: "0.3em",
    },
    "& .ql-snow.ql-toolbar": {
      display: "block",
      background: "#eaecec",
      borderTopLeftRadius: "0.3em",
      borderTopRightRadius: "0.3em",
    },
    "& .ql-editor": {
      minHeight: "15em",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      color: props.readOnly ? "rgba(0, 0, 0, 0.38)" : "black",
    },
    "& .ql-editor.ql-blank::before": {
      fontStyle: "normal",
      color: props.readOnly ? "rgba(0, 0, 0, 0.38)" : "black",
      fontSize: "16px",
    },
    "& .ql-editor:focus.ql-blank::before": {
      visibility: "hidden", // Hide the placeholder when the editor is focused
    },
    "& .ql-formats": {
      opacity: props.readOnly ? 0.6 : 1,
      pointerEvents: props.readOnly ? "none" : "auto",
    },
  },
}));
