import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: { display: "flex", padding: "2em 18px 0 18px" },
  identifierInfoInput: {
    width: "calc(33% - 16px)",
  },

  /* LocationPageTab */

  locationPageTab_root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    display: "flex",
    gap: "56px",
  },
  locationPageTab_leftColumn: {
    width: "70%",
  },
  locationPageTab_rightColumn: {
    width: "30%",
  },

  /* Schedule and Hours */
  scheduleRoot: { padding: "1em", maxWidth: "1200px" },
  scheduleHeader: {
    width: "100%",
    borderBottom: "1px solid ghostwhite",
    paddingBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  scheduleOptionsWrapper: { display: "flex", flexDirection: "column", padding: "1em 0" },
  switch: { maxWidth: "12em" },
  daysWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderBottom: "1px solid #f3f3f3",
    padding: "1.5em 0",
  },

  characterCount: {
    display: "flex",
    position: "absolute",
    bottom: "8px",
    right: "16px",
  },

  /* Providers at this Location */
  providerCard: {
    margin: "16px 0",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    background: "#f3f3f3",
    "& > img": {
      width: "5rem",
      height: "5rem",
      borderRadius: "8px",
      objectFit: "cover",
    },
  },
}));
