import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { SpecimenConfirmResponse } from "views/SpecimenTracking/types";

export const useDeleteSpecimen = () => {
  const deleteSpecimen = (specimenId: number) => {
    const uri = `${API_ENDPOINTS.specimenTrackingDeleteSpecimen}/${specimenId}`;
    const resp = axiosClient.delete<SpecimenConfirmResponse>(uri);
    return resp;
  };

  return {
    deleteSpecimen,
  };
};
