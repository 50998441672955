import React, { useEffect, useState } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLocationContext } from "../../LocationContext";
import AddAnotherAdjustHours from "./AddAnotherAdjustHours";
import AdjustedHoursListContent from "./AdjustedHoursListContent";
import { areIntervalsOverlapping } from "date-fns";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { useStyles } from "../../utils/styles";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const AdjustedHours = ({ timePickers, disableEditButton, locationId }) => {
  const { classes } = useStyles();
  const app = useApp();
  const { locationData, setCurrentlyEditing, refetch } = useLocationContext();
  const { hoursAndSchedule } = locationData;
  const adjustedHours = hoursAndSchedule.adjustedHours || [];
  const [adjustedHoursEffectiveStart, setAdjustedHoursEffectiveStart] = useState<
    string | undefined
  >(undefined);
  const [adjustedHoursMessage, setAdjustedHoursMessage] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [breakStartTime, setBreakStartTime] = useState("");
  const [breakEndTime, setBreakEndTime] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [adjustedHoursList, setAdjustedHoursList] = useState(adjustedHours);
  const [overlapError, setOverlapError] = useState(false);
  const [addNewAdjustedHours, setAddNewAdjustedHours] = useState(false);

  useEffect(() => {
    if (hoursAndSchedule.adjustedHours) {
      setAdjustedHoursList(hoursAndSchedule.adjustedHours);
    }
  }, [adjustedHours.length]);

  useEffect(() => {
    if (!adjustedHoursEffectiveStart && adjustedHours.length > 0) {
      setAdjustedHoursEffectiveStart(adjustedHours[0].adjustedHoursEffectiveStart);
      setStartTime(adjustedHours[0].adjustStart);
      setEndTime(adjustedHours[0].adjustEnd);
      setBreakStartTime(adjustedHours[0].adjustLunchStart);
      setBreakEndTime(adjustedHours[0].adjustLunchEnd);
      setAdjustedHoursMessage(adjustedHours[0].adjustedHoursMessaging || "");
    }
  }, [adjustedHours, adjustedHoursEffectiveStart]);

  const createDateTime = (date, hours, minutes) => new Date(date.setHours(hours, minutes, 0, 0));

  const addOrUpdatAdjustedHours = async () => {
    try {
      await axiosClient.patch(
        `${API_ENDPOINTS.dataCurationLocations}/adjusted-hours/${locationId}`,
        {
          adjustedHours: [
            {
              adjustedHoursEffectiveStart,
              adjustedHoursEffectiveEnd: adjustedHoursEffectiveStart,
              adjustedHoursMessaging: adjustedHoursMessage,
              adjustStart: startTime,
              adjustEnd: endTime,
              adjustLunchStart: breakStartTime,
              adjustLunchEnd: breakEndTime,
              adjustClosed: false,
            },
          ],
        },
      );
      refetch();
      setAddNewAdjustedHours(false);
      app.addInfoMsg("Adjusted Hours added successfully");
    } catch (error) {
      app.addError("Error Updating/Adding Adjusted Hours");
    }
  };

  const deleteAdjustedHours = async (currentAdjustHours) => {
    const dataPayload = { locationId, adjustedHours: currentAdjustHours };
    try {
      await axiosClient.delete(`${API_ENDPOINTS.dataCurationLocations}/adjusted-hours`, {
        data: dataPayload,
      });
      refetch();
      app.addInfoMsg("Adjusted Hours deleted successfully");
    } catch (error) {
      refetch();
      app.addError("Error Deleting Adjusted Hours");
    }
  };

  const handleAdjusted = async () => {
    if (!isButtonClicked && !addNewAdjustedHours) {
      setCurrentlyEditing("AdjustedHours");
      setIsButtonClicked((prev) => !prev);
      return;
    }
    if (!adjustedHoursEffectiveStart) {
      return;
    }
    if (adjustedHoursList.length > 0) {
      const {
        adjustStart,
        adjustEnd,
        adjustedHoursEffectiveStart: effectiveDate,
      } = adjustedHoursList[0];
      const [startHours, startMinutes] = (startTime || "0:0").split(":").map(Number);
      const [endHours, endMinutes] = (endTime || "0:0").split(":").map(Number);

      const intervalUser = {
        start: createDateTime(new Date(adjustedHoursEffectiveStart), startHours, startMinutes),
        end: createDateTime(new Date(adjustedHoursEffectiveStart), endHours, endMinutes),
      };

      if (addNewAdjustedHours) {
        const [adjustStartHours, adjustStartMinutes] = adjustStart.split(":").map(Number);
        const [adjustEndHours, adjustEndMinutes] = adjustEnd.split(":").map(Number);

        const intervalAdjusted = {
          start: createDateTime(new Date(effectiveDate), adjustStartHours, adjustStartMinutes),
          end: createDateTime(new Date(effectiveDate), adjustEndHours, adjustEndMinutes),
        };
        const overlapExists = areIntervalsOverlapping(intervalUser, intervalAdjusted);
        if (overlapExists) {
          setOverlapError(true);
          return;
        }
      }
    }
    setOverlapError(false);
    addOrUpdatAdjustedHours();
    if (!addNewAdjustedHours) {
      setIsButtonClicked((prev) => !prev);
    }

    if (isButtonClicked) {
      setCurrentlyEditing(null);
    } else {
      setCurrentlyEditing("AdjustedHours");
    }
  };

  const formatDate = (date) => {
    const newDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate(),
    ).padStart(2, "0")}`;
    setAdjustedHoursEffectiveStart(newDate);
  };

  const handleCancel = () => {
    setAdjustedHoursList(hoursAndSchedule.adjustedHours || []);
    setIsButtonClicked(false);
    setCurrentlyEditing(null);
  };

  const returnAdjustedHoursTimePickerValue = (label, currentAdjustHours) => {
    const timeValue = {
      "Start Time": currentAdjustHours.adjustStart,
      "End Time": currentAdjustHours.adjustEnd,
      "Break Start": currentAdjustHours.adjustLunchStart,
      "Break End": currentAdjustHours.adjustLunchEnd,
    }[label];

    return timeValue
      ? new Date(`${currentAdjustHours.adjustedHoursEffectiveStart}T${timeValue}:00`)
      : null;
  };

  const addAdjustedHours = () => {
    setAddNewAdjustedHours(true);
  };

  function formatTime(startTimeString) {
    const date = new Date(startTimeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    return formattedTime;
  }

  let buttonLabel: string;
  if (isButtonClicked || addNewAdjustedHours) {
    buttonLabel = "Save";
  } else {
    buttonLabel = "Edit";
  }

  const onChangeEditTimePickers = (timeString, picker) => {
    const NewTime = formatTime(timeString);
    if (picker.label === "Start Time") {
      setStartTime(NewTime);
    }
    if (picker.label === "End Time") {
      setEndTime(NewTime);
    }
    if (picker.label === "Break Start") {
      setBreakStartTime(NewTime);
    }
    if (picker.label === "Break End") {
      setBreakEndTime(NewTime);
    }
  };

  return (
    <Box marginTop="24px" display="flex" flexDirection="column">
      <Box className={classes.scheduleHeader}>
        <Typography variant="h6">Adjusted Hours</Typography>
        {(adjustedHours.length > 0 || addNewAdjustedHours) && (
          <Box>
            {isButtonClicked && (
              <Button
                onClick={() => handleCancel()}
                variant="contained"
                color="primary"
                style={{ width: "64px", height: "40px", marginRight: "10px" }}
              >
                Cancel
              </Button>
            )}
            <Button
              onClick={() => handleAdjusted()}
              variant={isButtonClicked ? "contained" : "outlined"}
              color="primary"
              disabled={disableEditButton}
              style={{ width: "64px", height: "40px" }}
            >
              {buttonLabel}
            </Button>
          </Box>
        )}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {adjustedHoursList.length ? (
          adjustedHoursList.map((currentAdjustHours, i) => (
            <div key="time-hour">
              <Box className={classes.daysWrapper} style={{ borderBottom: "none" }}>
                <DatePicker
                  label="Date"
                  minDate={new Date()}
                  value={new Date(currentAdjustHours.adjustedHoursEffectiveStart)}
                  onChange={(date) => formatDate(date)}
                  disabled={!isButtonClicked}
                />
                {timePickers.map((picker) => (
                  <TimePicker
                    key={picker.name}
                    label={picker.label}
                    value={returnAdjustedHoursTimePickerValue(picker.label, currentAdjustHours)}
                    onChange={(timeString) => onChangeEditTimePickers(timeString, picker)}
                    disabled={!isButtonClicked}
                  />
                ))}
              </Box>
              <Grid container style={{ paddingBottom: "1.5em", borderBottom: "1px solid #f3f3f3" }}>
                <Grid item xs={9.6} md={9.6} paddingTop="24px">
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      error={false}
                      id="adjustedHoursMessaging"
                      name="adjustedHoursMessaging"
                      size="medium"
                      defaultValue={currentAdjustHours.adjustedHoursMessaging}
                      variant="outlined"
                      label="Messaging"
                      disabled={!isButtonClicked}
                      helperText={false}
                      data-cy="Practice-adjustedHoursMessaging"
                      data-testid="Practice-adjustedHoursMessaging"
                      onChange={(event) => setAdjustedHoursMessage(event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.4} md={2.4} paddingTop="24px">
                  {isButtonClicked ? (
                    <Button
                      onClick={() => deleteAdjustedHours(currentAdjustHours)}
                      variant="contained"
                      color="primary"
                      style={{ width: "160px", height: "40px", marginTop: "6px" }}
                      startIcon={<DeleteIcon />}
                    >
                      DELETE
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </div>
          ))
        ) : (
          <AdjustedHoursListContent
            adjustedHoursList={adjustedHoursList}
            addNewAdjustedHours={addNewAdjustedHours}
          />
        )}
      </LocalizationProvider>
      {addNewAdjustedHours && (
        <AddAnotherAdjustHours
          setStartTime={setStartTime}
          addNewAdjustedHours={addNewAdjustedHours}
          setAddNewAdjustedHours={setAddNewAdjustedHours}
          adjustedHoursEffectiveStart={adjustedHoursEffectiveStart}
          setAdjustedHoursEffectiveStart={setAdjustedHoursEffectiveStart}
          setEndTime={setEndTime}
          setBreakStartTime={setBreakStartTime}
          setBreakEndTime={setBreakEndTime}
          overlapError={overlapError} // New prop for error indication
          setAdjustedHoursMessage={setAdjustedHoursMessage}
        />
      )}
      {!!overlapError && (
        <div style={{ color: "red", marginLeft: "20px" }}>
          These adjusted hours overlap with other date and time. Please Edit
        </div>
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => addAdjustedHours()}
        disabled={disableEditButton}
        style={{ width: "160px", height: "40px", marginTop: "16px" }}
        startIcon={<AddIcon />}
      >
        {adjustedHoursList.length ? "ADD ANOTHER" : "ADD"}
      </Button>
    </Box>
  );
};

export default AdjustedHours;
