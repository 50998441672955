import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import AdjustedHours from "./AdjustedHours";
import DaysTimePickers from "./DaysTimePickers";
import HoursAndScheduleHeader from "./HoursAndScheduleHeader";
import { timePickers } from "../../utils/consts";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { useStyles } from "../../utils/styles";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const HoursAndSchedule = () => {
  const app = useApp();
  const { locationId } = useParams<{ locationId: string }>();
  const { classes } = useStyles();
  const { currentPayload, setCurrentPayload, currentlyEditing, setCurrentlyEditing } =
    useLocationContext();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (section: string) => {
    if (!isEditing) {
      setIsEditing(true);
      setCurrentlyEditing(section);
      return;
    }

    setIsLoading(true);

    if (currentPayload) {
      try {
        const response = await axiosClient.patch(
          `${API_ENDPOINTS.dataCurationLocations}/${locationId}`,
          { ...currentPayload },
        );

        setCurrentPayload(null);
        app.addInfoMsg(response.data.message);
      } catch (error) {
        setCurrentPayload(null);
        app.addError("Error updating location. Please try again.");
      } finally {
        setIsLoading(false);
        setIsEditing(false);
        setCurrentlyEditing(null);
      }
    }

    setIsLoading(false);
    setIsEditing(false);
    setCurrentlyEditing(null);
  };

  return (
    <main className={classes.scheduleRoot}>
      <HoursAndScheduleHeader
        isEditing={isEditing && currentlyEditing === "HoursAndScheduleHeader"}
        handleSave={() => handleSave("HoursAndScheduleHeader")}
        isLoading={isLoading}
        disableEditButton={
          currentlyEditing !== null && currentlyEditing !== "HoursAndScheduleHeader"
        }
      />

      <Box className={classes.scheduleOptionsWrapper}>
        <DaysTimePickers isEditing={isEditing && currentlyEditing === "HoursAndScheduleHeader"} />
        <AdjustedHours
          timePickers={timePickers}
          disableEditButton={currentlyEditing !== null && currentlyEditing !== "AdjustedHours"}
          locationId={locationId}
        />
      </Box>
    </main>
  );
};

export default HoursAndSchedule;
