import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { useDeleteSpecimen } from "hooks/specimenTracking/useDeleteSpecimen/useDeleteSpecimen";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    textAlign: "center",
    width: "100%",
  },
  "& .MuiDialogContent-root p": {
    marginTop: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiDialogActions-root > button: first-child": {},
  "& .MuiDialogActions-root button": {
    marginTop: theme.spacing(2),
  },
}));

const DeleteSpecimenModal = ({ open, onClose, specimenDetail }) => {
  const { addError, addInfoMsg } = useApp();

  const {
    web: {
      specimenTracking: { confirmDeleteSpecimenTitle, confirmDeleteSpecimenMsg },
    },
  } = useTranslation();

  const { deleteSpecimen } = useDeleteSpecimen();
  const navigate = useNavigate();

  const handleDeleteSpecimen = useCallback(
    async () => {
      // eslint-disable-next-line no-alert
      try {
        await deleteSpecimen(specimenDetail.id);
        addInfoMsg("Specimen has been deleted successfully.");
        onClose();
        navigate("/console/specimen-tracking");
      } catch {
        addError("Problem deleting specimen, please contact support.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addError, addInfoMsg, specimenDetail],
  );

  return (
    <CustomDialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      data-testid="Dialog"
    >
      <DialogTitle id="confirmation-dialog-title">{confirmDeleteSpecimenTitle}</DialogTitle>
      <DialogContent dividers>
        <b>Are you sure you want to delete specimen '{specimenDetail.specimenExternalId}'?</b>
        <p>{confirmDeleteSpecimenMsg}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" fullWidth>
          Cancel
        </Button>
        <Button
          onClick={handleDeleteSpecimen}
          variant="outlined"
          color="primary"
          data-testid="onOk"
        >
          <span>Confirm Delete</span>
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default DeleteSpecimenModal;
